<template>
  <div>
    <div>


      <VTextField v-if="step === 1" label="Nome do campo" v-model="nomeCampo"/>

      <div v-if="step === 2">
        Selecione o tipo de metadado:

        <div style="margin-bottom: 10px; margin-top: 10px; display: flex; gap: 5px" >
          <v-btn @click="setType('array')">Lista</v-btn>
          <v-btn @click="setType('object')">Objeto</v-btn>
          <v-btn @click="setType('string')">Texto</v-btn>
        </div>
      </div>

      <div v-if="step === 3">


        <div v-if="tipo === 'object'">
          <div style="margin-bottom: 10px; margin-top: 10px; display: flex; gap: 5px">
            <VTextField label="Identificador" v-model="identificador"/>
            <VTextField label="Valor" v-model="valor"/>

            <v-btn @click="addObjectFieldToArray">Adicionar novo campo</v-btn>
          </div>
        </div>

        <div v-if="tipo === 'string'">
          <div style="margin-bottom: 10px">
            <VTextField label="Texto" v-model="texto"/>
          </div>
        </div>


        <div v-if="tipo === 'array'">
          <div style="margin-bottom: 10px; margin-top: 10px; display: flex; gap: 5px"  v-if="arrayType === ''">
            <v-btn @click="arrayType = 'object'">Objeto</v-btn>
            <v-btn @click="arrayType = 'string'">Texto</v-btn>
          </div>

          <div v-else>
            <div style="margin-bottom: 10px; margin-top: 10px; display: flex; gap: 5px; flex-direction: column"  v-if="arrayType === 'object'">
              <VTextField label="Identificador" v-model="identificador"/>
              <VTextField label="Valor" v-model="valor"/>

              <v-btn @click="addObjectField">Adicionar novo campo</v-btn>
            </div>
            <div style="margin-bottom: 10px"  v-else>
              <VTextField label="Texto" v-model="arrayText"/>
            </div>

            <v-btn style="margin-bottom: 10px" @click="pushToArray">Proximo dado da lista</v-btn>
          </div>
        </div>

      </div>



      <v-btn @click="nextStep()">Proximo passo</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewMetadata",
  data(){
    return {
      nomeCampo: "",
      step: 1,
      tipo: '',
      identificador: '',
      valor: '',
      objetoFinal: {},
      texto: '',
      arrayType: '',
      arrayText: '',
      tempObject: {}
    }
  },
  methods: {
    setType(type){
      this.tipo = type
      this.nextStep()
    },
    addObjectField(){
      this.tempObject[this.identificador] = this.valor

      this.identificador = ''
      this.valor = ''
    },
    addObjectFieldToArray(){
      this.objetoFinal[this.nomeCampo][this.identificador] = this.valor
      this.identificador = ''
      this.valor = ''
      console.log(this.tempObject)
    },
    pushToArray(){
      if(this.arrayType === 'object'){
        this.addObjectField()
        this.objetoFinal[this.nomeCampo].push(this.tempObject)
        this.tempObject = {}
      }
      if(this.arrayType === 'string'){
        this.objetoFinal[this.nomeCampo].push(this.arrayText)
        this.arrayText = ''
      }

      // this.arrayType = ''
    },
    nextStep(){
      if(this.step === 1){
        this.objetoFinal[this.nomeCampo] = null
      }

      if(this.step === 2){
        if(this.tipo === 'string'){
          this.objetoFinal[this.nomeCampo] = ''
        }
        if(this.tipo === 'array'){
          this.objetoFinal[this.nomeCampo] = []
        }
        if(this.tipo === 'object'){
          this.objetoFinal[this.nomeCampo] = {}
        }
      }

      if(this.step === 3){
        if(this.tipo === 'string'){
          this.objetoFinal[this.nomeCampo] = this.texto
        }

        if(this.tipo === 'object'){
          this.objetoFinal[this.nomeCampo][this.identificador] = this.valor
        }

        if(this.tipo === 'array'){
          if(this.arrayType === 'object'){
            this.addObjectField()
            this.objetoFinal[this.nomeCampo].push(this.tempObject)
          }

          if(this.arrayType === 'string'){
            this.objetoFinal[this.nomeCampo].push(this.arrayText)
          }
        }

        this.$emit('newMetadata', this.objetoFinal)
      }

      this.step++
    }
  }
}
</script>

<style scoped>

</style>
