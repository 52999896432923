<template>
  <div class="background-card" :class="{grande: grande, pequeno: pequeno}">
    <div style="width: 100%;">

      <v-data-table
          :headers="header"
          :items="array_filter"

          :search="search"

          :server-items-length="totalPage"
          :page="page"
          :items-per-page="1"
          class="elevation-1"

          @pagination="getData"
          hide-default-footer
          hide-default-header
      >

        <template v-slot:header="{ props: { headers } }">
          <thead>
          <tr>
            <th v-for="(h, index) in headers" :key="h+index" class="test">
              <h4 style="text-align: center">{{h.text}}</h4>
            </th>
          </tr>
          </thead>
        </template>


        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                color="primary"
            ></v-text-field>
            <v-spacer />
            <v-btn
                color="primary"
                class="ma-2 white--text"
                @click="geraExcel()"
            >
              <v-icon
                  dark
                  style="margin-right: 10px"
              >
                mdi-file-excel-outline
              </v-icon>
              Excel
            </v-btn>
          </v-toolbar>
        </template>


        <template v-slot:item.avatar="{ item }">
          <div class="buttons">
            <v-icon
                size="24"
                :color="item.email_reclassification ? '#00E28C' : 'primary'"
            >
              {{ item.email_reclassification ? 'la-check' : icon }}
            </v-icon>
          </div>
        </template>

        <template v-slot:item.name="{ item }">
          <div style="display: flex; flex-direction: column">
            <h4 style="display: flex; flex-direction: row; align-items: center; font-size: 0.8rem" >Nome: <h5 style="font-weight: normal;font-size: 0.8rem; height: 100%; margin-left: 0.5rem">{{ item.name }}</h5></h4>
            <h4 style="display: flex; flex-direction: row; align-items: center; font-size: 0.8rem" >Email: <h5 style="">{{ item.email }}</h5></h4>
          </div>
        </template>

        <template v-slot:item.result="{ item }">
          <div style="display: flex; flex-direction: column">
            <h4 style="display: flex; flex-direction: row; align-items: center; font-size: 0.8rem" ><h5 style="font-weight: normal;font-size: 0.7rem; height: 100%; margin-left: 0.5rem">{{ item.result }}</h5></h4>
          </div>
        </template>

        <template v-slot:item.classification="{ item }">
          <div style="display: flex; flex-direction: column; margin: 0.5rem 0;">
            <h4  style="display: flex; flex-direction: row; align-items: center; font-size: 0.7rem">Classificação:</h4><h5 style="font-weight: normal;margin-left: 0; font-size: 0.75rem; height: 100%; margin-left: 0;text-align: left">{{ item.classification }}</h5>
            <h4 v-if="item.email_reclassification" style="display: flex; flex-direction: row; font-size: 0.7rem" >Reclassificação:<br></h4><h5 v-if="item.email_reclassification" style="margin-left: 0; text-align: left; color: #442cd2">{{ item.email_reclassification.email_new_classification }}</h5>
          </div>
        </template>

        <template v-slot:item.created_at="{ item }">
          <div style="display: flex; flex-direction: column">
            <h4 style="display: flex; flex-direction: row; align-items: center; font-size: 0.8rem" >Processado: <h5 style="font-weight: normal;font-size: 0.8rem; height: 100%; margin-left: 0.5rem">{{ item.created_at | horaFormat}}</h5></h4>
            <h4 style="display: flex; flex-direction: row; align-items: center; font-size: 0.8rem" >Recebido: <h5 style="">{{ item.received_at | semHora}}</h5></h4>
          </div>
        </template>


        <template v-slot:item.options="{ item }">
          <div class="buttons">
            <v-btn
                fab
                text
                small
                color="white"
                @click.native="$emit('openModal', item)"
            >
              <v-icon
                  size="24"
                  color="grey"
                  class="icone"
              >
                la-envelope-open-text
              </v-icon>
            </v-btn>
          </div>
        </template>


        <template v-slot:no-data>
          <p>Sem dados</p>
        </template>


      </v-data-table>

      <div class="text-center pt-2 mt-5 mb-5">
        <v-pagination
            v-model="page"
            :length="totalPage"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>


<script>
import moment from "moment";
import {mapActions} from "vuex";

export default {
  name: 'GenericDataTable',
  components: {

  },
  data() {
    return {
      page: 1,
      totalPage: 1,
      tableData: [],
      search: '',

      list: []
    }
  },
  filters: {
    horaFormat(value) {
      if (value){
        return moment(value.split('.', 1)[0].split(' ')[0]).format("DD/MM/YYYY") + ' ' + value.split('.', 1)[0].split(' ')[1]
      }
      return value
    },
    semHora(value) {
      if (value){
        return moment(value.split('.', 1)[0].split(' ')[0]).format("DD/MM/YYYY")
      }
      return value
    }
  },
  props: {
    action: {},
    header: {},
    filters: {},
    perPage: {
      type: Number,
      default: 20,
    },
    refresh: {},

    routeCreate: {},
    routeEdit: {},
    icon: {},
    grande: {},
    pequeno: {},

    blocos: {},
    fornecedores: {},
  },

  // mounted() {
  //   this.getData();
  // },

  methods: {
    ...mapActions(['GET_EXCEL']),

    getData(){
      let filters = {
        ...this.filters,
        per_page: this.perPage,
        page: this.page,
      }
      this.$store.dispatch(this.action, filters).then(res => {
        this.tableData = res.data.data;
        this.totalPage = res.data.last_page;
        window.scroll({top: 0, behavior: "smooth"})
      });
    },
    geraExcel() {
      let filters = {
        ...this.filters,
      }
      this.GET_EXCEL(filters).then(response => {
        let blob = new Blob([response.data], {type: ""})
        this.forceDownload(window.URL.createObjectURL(blob))
      })
    },
    forceDownload(data) {
      let link = document.createElement('a')
      link.href = data
      link.download = 'Log_CRM.xlsx'
      link.click()
    },
  },

  computed: {
    array_filter() {
      return this.tableData.filter(item_list => {
        return item_list.email.toLowerCase().includes(this.search.toLowerCase()) ||
            item_list.email_id.toString().includes(this.search) ||
            item_list.classification.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').includes(this.search)
      })
    }
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    refresh: {
      handler() {
        this.getData();
      }
    },
  }


}
</script>


<style scoped>
h5 {
  font-weight: normal;
  font-size: 0.8rem;
  height: 100%;
  margin-left: 0.5rem;
}


.retangular-button {
  box-shadow:  0 0 0.1em black !important;
  display: flex;
  font-size: 0.8rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.icone:hover {
  color: #3c2e88 !important;
}

.lixo:hover {
  color: red !important;
}

.grande {
  width: 95%;
}

.pequeno {
  width: 50%;
}

</style>
