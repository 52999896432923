<template>
  <div
      class="retangular-button"
      v-cloak
      :class="{colorRoxo: color === 'roxo', colorVermelho: color === 'vermelho', colorAzul: color === 'azul', colorBranco: color === 'branco', colorTeste: color === 'teste'}"
  >
    <h3>
      {{ text }}
    </h3>
  </div>
</template>
<script>
export default {
  name: 'RetangularButton',
  props: {
    color: {
      default: 'roxo'
    },
    text: {
      default: 'Nome do botão',
    },
    opened: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped>

.retangular-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 40px;
  border-radius: 8px;
  box-shadow: 0 0 0.2em black;
  cursor: pointer;
}

h3 {
  color: white;
  font-family: 'Verdana';
}

.colorRoxo {
  background: #5d30b9;
}

.colorRoxo:hover {
  background: #3C2E88;
}

.colorVermelho {
  background: #E33E5A;
}

.colorVermelho:hover {
  background: #b63248;
}

.colorAzul {
  background: #002E5D;
}

.colorAzul:hover {
  background: #00165d;
}

.colorBranco {
  background: #002E5D;
}

.colorBranco h3 {
  color: white;
}

.colorTeste {
  background: #f5eeee;
}

.colorTeste h3{
  color: #002E5D;
}
/*.colorTeste:hover {*/
/*  background: ;*/
/*}*/

/*.colorBranco:hover {*/
/*  background: #002E5D;*/
/*}*/

</style>