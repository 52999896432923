<template>
    <div class="tree">
        <div class="titulo text-center elevation-1">
            <h3>Decision Tree</h3>
        </div>

        <div> 
            <v-dialog v-model="dialogLoading" max-width="200px">
                <v-card>
                    <v-card-title class="headline text-center">Carregando...</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon class="mdi-spin">mdi-loading</v-icon>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <div>
            <v-dialog v-model="dialogRecover" max-width="500px">
                <v-card>
                    <v-card-title class="headline text-center">Recuperação de versão</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-select v-model='version_select' :items="workflow_versions" item-text="id" item-value="id" label="Selecione a versão:" dense></v-select>
                        <v-btn class="ml-8" color="blue darken-1" text @click="recover(version_select)">Recuperar</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <!--<div>
            <v-dialog v-model="dialogDelete" max-width="470px">
                <v-card>
                    <v-card-title class="headline text-center">Tem certeza que quer excluir este nó?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="blue darken-1" text @click="confirmDeleteNode">Sim</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>-->

        <div class="mx-2 mt-3" style="width:100%;" v-if="this.items == ''">
            <v-dialog transition="dialog-top-transition" max-width="800">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block elevation="2" class='mr-2' @click.native="newTree()" v-bind="attrs"
                                v-on="on" @click="newTree()">
                                <h4>CRIAR ÁRVORE</h4>
                            </v-btn>
                           
                        </template>

                        <!-- processo de criação do dialog -->

                        <template v-slot:default="dialog">
                            <v-card>
                                <v-card-title>
                                <span class="text-h5">{{isEdit ? "Edit Nodes" : "Create Nodes"}}</span>
                                </v-card-title>
                                <v-card-text>
                                <v-form>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                            <v-text-field
                                            label="Node Name*"
                                            v-model="nodeName"
                                            :rules="nodeName_rules"
                                            required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                            <v-text-field
                                            label="Parent Response*"
                                            :rules="parentResponse_rules"
                                            v-model="parentResponse"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                            <v-text-field
                                            label="Workers Queue*"
                                            :rules="workerQ_rules"
                                            v-model="workerQ"
                                            required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-select
                                            :items="['break', 'loop', 'single']"
                                            label="type*"
                                            :rules="nodeType_rules"
                                            v-model="nodeType"
                                            required
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <small>*indicates required field</small>
                                </v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="dialog.value = false">
                                    Cancelar
                                </v-btn>
                                <v-btn
                                    @click="addChild(items); dialog.value = false"
                                >
                                    Salvar
                                </v-btn>
                            </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
        </div>

        <!-- criação treeview dentro de um único card -->

            <div v-if="this.items != ''" style="width:100%; display: inline-block;">
            <v-card
            elevation="2"
            class="card-table mx-2"
            >
                <v-treeview
                :items="items"
                open-all
                >

                <!-- botões dentro de cada node do treeview -->
                <!-- alterações são aplicadas em todos os nodes existentes e novos -->

                <template v-slot:prepend="{ item }">
                    <v-dialog transition="dialog-top-transition" max-width="800">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="!item.file">
                                mdi-file-tree
                            </v-icon>
                            <v-btn
                                class="mr-2"
                                fab 
                                x-small
                                @click="deleteNode(item)"
                                >
                                <v-icon title="Excluir">mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <v-btn 
                                class="mx-2" 
                                fab 
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                @click="getIdEdit(item); (isEdit = true)"
                                >
                                <v-icon title="Editar nó">mdi-image-edit-outline</v-icon>
                            </v-btn>
                            <v-btn 
                                id='addTree' 
                                class="mx-2" 
                                fab 
                                x-small
                                v-bind="attrs"
                                v-on="on" 
                                @click="getIdCreate(item.id); (isEdit = false)"
                                >
                                <v-icon title="Criar nó filho">mdi-file-plus-outline</v-icon>
                            </v-btn>
                        </template>

                        <!-- processo de criação do dialog -->

                        <template v-slot:default="dialog">
                            <v-card>
                                <v-card-title>
                                <span class="text-h5">{{isEdit ? "Edit Nodes" : "Create Nodes"}}</span>
                                </v-card-title>
                                <v-card-text>
                                <v-form ref="form">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                            <v-text-field
                                            label="Node Name*"
                                            v-model="nodeName"
                                            :rules="nodeName_rules"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                            <v-text-field
                                            label="Parent Response*"
                                            :rules="parentResponse_rules"
                                            v-model="parentResponse"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                            <v-text-field
                                            label="Workers Queue*"
                                            :rules="workerQ_rules"
                                            v-model="workerQ"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-select
                                            :items="['break', 'loop', 'single']"
                                            label="type*"
                                            :rules="nodeType_rules"
                                            v-model="nodeType"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <small>*indicates required field</small>
                                </v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="dialog.value = false">
                                    Cancelar
                                </v-btn>
                                <v-btn
                                    :disabled="nodeName===''||nodeType===''||workerQ===''||parentResponse===''"
                                    @click="isEdit ? editChild(item) : addChild(item) ; dialog.value = false"
                                >
                                    Salvar
                                </v-btn>
                            </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </template>

                </v-treeview>
            </v-card>
        </div>

        <!-- botões ao fim da tela, sujeitos a mudança de design -->

        <div class="mt-7 mb-5" style="width:100%; display:flex">
            <v-col cols="12" sm="4" md="4">
                <v-btn id="voltar" block elevation="2" class='mr-2' @click.native="$router.push({name: 'Workflow'}).catch(() => {})">
                    <h4>VOLTAR</h4>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-btn class='' block elevation="2" @click.native="dialogRecover=true;">
                    <h4>RECUPERAR VERSÃO</h4>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-btn color='#0d4e79' class='white--text ml-2' block elevation="2"
                @click.native="finalDeleteNode();">
                <h4>SALVAR ÁRVORE</h4>
                </v-btn>
            </v-col>
        </div>

    </div>
</template>

<script>

import axios from '../../plugins/axios'
import {ref} from 'vue'

  export default {
    name: "TreeMain", 
    components: {

    },
    setup() {
        const modalActive = ref(false);

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        return {modalActive, toggleModal};
    },

    data: () => ({
        isEdit: false,
        dialog: false,
        dialogDelete: false,
        dialogLoading: true,
        dialogRecover: false,
        temp: [ 
            {
                name: '',
                workr_queue: '',
                id: '',
                parent_node_id: '',
                parent_response: '',
                type: '',
            }
        ],
        workflow_versions: [], //a lista de versões do workflow
        db_id: localStorage.treeID, //armazenamento do id do workflow vindo do back
        db_version: localStorage.WorkflowVersion, //armazenamento do id relacionado a versão do workflow vindo do back
        tempLength: '', //armazena o .length do workflow

        nodeName: '',           //essas variáveis armazenam os inputs
        parentResponse: '',     //dos diálogos.
        parent_id: '',          //
        id: '',                 //
        nodeType: '',           //
        workerQ: '',            //
        new_root_node_id: '',   //

        itemDelete: [],
        version_select: '',
        contNodes: 0,
        isOpen: false,
        file: '', 
        files: {
            end: 'mdi-clipboard-outline',
        },
        nodeName_rules: [
            value => !!value || 'Required.',
        ],
        parentResponse_rules: [
            value => !!value || 'Required.',
        ],
        workerQ_rules: [
            value => !!value || 'Required.',
        ],
        nodeType_rules: [
            value => !!value || 'Required.',
        ],
        tree: [],
        items: [],
        itemsInfo: [],
    }),
    computed: {
        isFolder() {
            return this.item.children && this.item.children.length;
        }
    },

    methods: {

        toggle() {
            if (this.isFolder) {
              this.isOpen = !this.isOpen;
            }
        },
        addChild(item) {
            console.log('addChild')
            this.dialogLoading = true
            this.isOpen = true;

            var dados = {
                name: this.nodeName,
                type: this.nodeType,
                parent_response: this.parentResponse,
                workr_queue: this.workerQ,
                parent_node_id: this.parent_id,
                children: [],
            }

            axios
                .post('/node', dados, {
                    headers: {
                        "Authorization": localStorage.access_token,
                    },
                })
                .then((response) => {
                    if (this.items != '') {
                        if(!item.children) {
                            this.$set(item, "children", []);
                        }
                        item.children.push(response.data.data);
                    } else{
                        this.items = [response.data.data];
                        console.log('item', item)
                        console.log('items', this.items)
                        this.new_root_node_id = response.data.data.id
                        this.firstSave()
                    }
                    this.dialogLoading = false
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        checkTree() { 
            if (this.db_version == 'null') {
                 console.log('Nehuma Árvore cadastrada!!!')
                this.dialogLoading = false
            }else{
                axios.get('/workflow_version/' + this.db_version, {
                    headers: {
                        "Authorization": localStorage.access_token,
                    },
                })
                    .then((response) => {
                        if (response.data == undefined) {
                            this.dialogLoading = false
                            return
                        }
                        else {
                            this.items = [JSON.parse(response.data.data.decision_tree)];
                            this.contNodes = (response.data.data.decision_tree.match(/parent_node_id/g)).length
                            console.log('nodes:', this.contNodes)
                        }
                        this.dialogLoading = false
                    })
                    .catch((error) => {
                        console.log(error);
                    })

                axios.get('/workflow_version?workflow_id=' + this.db_id,{
                    headers: {
                        "Authorization": localStorage.access_token,
                    },
                })
                    .then((response) => {
                        this.workflow_versions = response.data.data
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        },

        editChild(item) {
            this.dialogLoading = true
            this.isOpen = true;
            const dados = {
                name: this.nodeName,
                type: this.nodeType,
                parent_response: this.parentResponse,
                workr_queue: this.workerQ,
                id: this.id
            }
            axios
                .put('/node/'+ dados.id, dados, {
                    headers: {
                        "Authorization": localStorage.access_token,
                    },
                })
                .then((response) => {
                    var res = response.data.data
                    item.name = res.name
                    item.type = res.type
                    item.parent_response= res.parentRespparent_responseonse
                    item.workr_queue = res.workr_queue
                    this.dialogLoading = false
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        firstSave(){
            this.dialogLoading = true
            axios.post('/workflow_version/' + this.db_id, [], {
                headers: {
                    "Authorization": localStorage.access_token,
                },
            })
                .then((response) => {
                    const work_version = response.data.id
                    axios.get('/workflow/' + this.db_id, {
                        headers: {
                            "Authorization": localStorage.access_token,
                        },
                    })
                        .then((response) => {
                            const dados = {
                                description: response.data.data.description,
                                root_node_id: response.data.data.root_node_id != null ? response.data.data.root_node_id : this.new_root_node_id,
                                trigger_frequency: response.data.data.trigger_frequency,
                                status: response.data.data.status,
                                metadata: JSON.parse(response.data.data.metadata),
                                workflow_version: work_version,
                            }
                            axios.put('/workflow/' + this.db_id, dados, {
                                headers: {
                                    "Authorization": localStorage.access_token,
                                },
                            })
                                .then((response) => {
                                    this.db_version = response.data.data.workflow_version
                                    this.dialogLoading = false;
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        saveTree() {
            this.dialogLoading = true
            axios.post('/workflow_version/' + this.db_id, [], {
                headers: {
                    "Authorization": localStorage.access_token,
                },
            })
                .then((response) => {
                    const work_version = response.data.id
                    axios.get('/workflow/' + this.db_id,{
                        headers: {
                            "Authorization": localStorage.access_token,
                        },
                    })
                        .then((response) => {
                        const dados = {
                            description: response.data.data.description,
                            root_node_id: response.data.data.root_node_id != null ? response.data.data.root_node_id : this.new_root_node_id,
                            trigger_frequency: response.data.data.trigger_frequency,
                            status: response.data.data.status,
                            metadata: JSON.parse(response.data.data.metadata),
                            workflow_version: work_version,
                        }
                            axios.put('/workflow/' + this.db_id, dados, {
                                    headers: {
                                        "Authorization": localStorage.access_token,
                                    },
                                })
                                .then((response) => {
                                    this.db_version = response.data.data.workflow_version
                                    this.dialogLoading = false;
                                    document.getElementById('voltar').click()
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) =>{
                console.log(error)
            })
        },

        getIdCreate(id) { //working to set parent_node_id
            this.parent_id = id;
            this.id = ''
            this.$refs.form.reset()
        },

        getIdEdit(item) {
            this.nodeName = item.name
            this.parent_id = item.parent_node_id
            this.parentResponse = item.parent_response
            this.id = item.id
            this.nodeType = item.type
            this.workerQ = item.workr_queue
        },

        newTree(){
            this.getIdCreate(null);
            this.firstSave();
            this.isEdit = false;
        },

        getWorkflow_version_id() {
            axios.get('/workflow_version',{
                headers: {
                    "Authorization": localStorage.access_token,
                },
            })
            .then((response) =>{
                getWorkflowLength()
                for(i = 0; i > this.tempLength; i++){
                    if(this.id_workflow == response.data.data[i].workflow_id){
                        this.parent_id = response.data.data[i].id
                    }
                }
            })
            .catch((error) =>{
                console.log(error);
            })
        },

        getWorkflowLength() {// working as intended
            axios.get('/workflow', {
                headers: {
                    "Authorization": localStorage.access_token,
                },
            })
            .then((response) =>{
                console.log('response: ', response);
                this.tempLength = response.data.data.length;
                console.log('tempLength: ', this.tempLength);
            })
            .catch((error) =>{
                console.log(error);
            })
        },

        loopDelete(item, id) {
            console.log('loop:', id, item)
            for (var i = 0; i < item.children.length; i++) {
                if (item.children[i].id == id) {
                    item.children.splice(i, 1)
                    return
                }
                if (item.children[i] != undefined) {
                    this.loopDelete(item.children[i], id)
                }
            }
        },

        deleteNode(item) { 
            this.itemDelete.push(item.id)
            this.loopDelete(this.items[0], item.id)
        },

        recover(id) {
            this.dialogLoading=true
            axios.get('/workflow/' + this.db_id, {
                headers: {
                    "Authorization": localStorage.access_token,
                },
            })
                .then((response) => {
                    const dados = {
                        description: response.data.data.description,
                        root_node_id: response.data.data.root_node_id != null ? response.data.data.root_node_id : this.new_root_node_id,
                        trigger_frequency: response.data.data.trigger_frequency,
                        status: response.data.data.status,
                        metadata: JSON.parse(response.data.data.metadata),
                        workflow_version: id,
                    }
                    axios.put('/workflow/' + this.db_id, dados, {
                        headers: {
                            "Authorization": localStorage.access_token,
                        },
                    })
                        .then((response) => {
                            this.db_version = response.data.data.workflow_version
                            this.dialogLoading = false;
                            document.getElementById('voltar').click()
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        finalDeleteNode() {
            for (var i = 0; i < this.itemDelete.length; i++) {
                console.log('itemDelete: ', this.itemDelete[i])
                axios
                    .delete(
                        "/node/" +
                        this.itemDelete[i],{
                            headers: {
                                "Authorization": localStorage.access_token,
                            },
                        })
                    .then((response) => {
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            this.saveTree()
        },

    },

    mounted(){
        this.checkTree();
    },

  }
</script>

<style scoped>
.tree{
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
}
.workflow{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
}
.data-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
}

.alert {
  display: flex;
  position: fixed;
  width: 72%;
  margin-top: 46%

}

.card-table {
  flex: 1 1 100%;
  border-radius: 6px;
}

.titulo {
  background-color: rgb(255, 255, 255);
  justify-content: center;
}

.hidden{
  display: none;
}
</style>
