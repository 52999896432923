import axios from '../../plugins/axios'


export const workflowStore = {
    state: () => ({
        workflows: [],
        workflow: {},
    }),
    mutations: {
        setWorkflow(state, payload) {
            state.workflow = payload;
        },
        setWorkflows(state, payload) {
            state.workflows = payload;
        },
    },
    actions: {
        GET_WORKFLOW({commit}, filters) {
            return new Promise((resolve) => {
                axios.get('/workflow' ,{
                    headers: {
                                "Authorization": localStorage.access_token,
                            },
                }) 
                    .then(response => {
                        let values = response.data
                        commit('setWorkflows', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
    },
    getters: {}
}

export default workflowStore;