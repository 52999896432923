import Vue from 'vue'
import Vuex from 'vuex'
import { exampleStore } from './modules/example'
import logStore from "./modules/logs";
import executionStore from "./modules/execution"
import workflowStore from "./modules/workflow"
import statusStore from "./modules/status";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    example: exampleStore,
    logs: logStore,
    execution: executionStore,
    workflow: workflowStore,
    status: statusStore

  },
  state: {
    loading: [],
  },
  mutations: {
    beginLoading(state) {
      //state.loading.push({
        //loading: false
      //})
    },
    finishLoading(state) {
      state.loading.pop()
    },
  },
  actions: {
  },
  getters: {
    loading: state => state.loading.length > 0,
  }
})
