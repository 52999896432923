<template>
  <v-col :cols="cols">
    <v-switch
        :label="label"
        color="primary"
        hide-details
        :input-value="valueField"
        @change="$emit('update:value', $event)"
    ></v-switch>
  </v-col>
</template>

<script>
export default {
  name: "DefaultSwitch",
  props: {label: {}, valueField: {}, cols:{}},
  data() {
    return {};
  }
};
</script>
