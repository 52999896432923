import axios from "../../plugins/axios";

export const statusStore = {
    state: () => ({
        status: [],
    }),
    mutations: {
        setStatus(state, payload) {
            state.status = payload;
        },
    },
    actions: {
        GET_STATUS({commit}) {
            return new Promise((resolve) => {
                axios.get('worker-log/get-status')
                    .then(response => {
                        let values = response.data.data
                        commit('setStatus', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        POST_STATUS(context, obj) {
            return new Promise((resolve) => {
                axios.post('worker-log/update-status', obj)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
    },
    getters: {}
}

export default statusStore;