<template>
  <div class="metadata-generator">
    <v-expansion-panels>
      <v-expansion-panel
          v-for="(metadata , index) in metadatas"
          :key="index"
      >
        <v-expansion-panel-header>
          <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between">
            {{ metadata.key }}
            <div>
              <v-btn
                  v-if="metadata.type == 'array'"
                  icon
                  @click.stop="addListStringMetaViewer(index)"
                >
                  <v-icon color="blue">
                    mdi-plus
                  </v-icon>
                
                </v-btn>
              <v-btn
                icon
                @click.stop="removeMetadata(metadata.key)"
              >
                <v-icon color="red">
                  mdi-trash-can
                </v-icon>
                
              </v-btn>
            </div>
          </div>

        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadItem">
          <MetadataViewer :metadata="metadata" @edit="handleEdit"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import MetadataViewer from '@/components/MetadataGenerator/MetadataViewer'

export default {
  name: 'MetadataGenerator',
  components: {
    MetadataViewer
  },
  props: {
    value: {
      type: Object | String,
      required: true
    }
  },
  data() {
    return {
      metadatas: [],
      reloadItem: 0,
    }
  },
  mounted() {
    this.map();
  },
  methods: {
    map() {
      let metadata = this.value != null && this.value != undefined ? this.value : {}
      let keys = Object.keys(metadata)

      this.metadatas = keys.map(key => {
        return {
          key: key,
          type: Array.isArray(metadata[key]) ? 'array' : typeof metadata[key],
          value: metadata[key]
        }
      })
    },
    removeMetadata(key) {
      this.$emit('removeMetadata', key)
    },
    addListStringMetaViewer(index) {
      this.metadatas[index].value.push('Novo Item')
      this.reloadItem++
      this.$emit('editMetadata', this.metadatas)
    },
    handleEdit(newValue) {
      this.metadata = newValue
      this.metadata.value = this.limpaItensVazios(this.metadata.value)
      console.log(this.metadata)
      this.$emit('editMetadata', this.metadata)
    },
    limpaItensVazios(dados) {
      if (Array.isArray(dados)) {
        return dados.filter((value) => value !== "");
      }
      return dados;
    },
    setMetadataValue(newValue) {
      this.metadata.value = newValue
    }
  },
}
</script>

<style>
.metadata-generator {
  min-width: 100%;
}
</style>
