<template>
  <div>
    <component 
      :is="selectedComponent" 
      :metadata="metadata" 
      @editStringMetaViewer="handleEditStringMetaViewer"
      @editObjMetaViewer="handleEditObjMetaViewer"
      @editListMetaViewer="handleEditListMetaViewer" 
    />
  </div>
</template>

<script>
import ArrayMetaViewer from "@/components/MetadataGenerator/TypesViewer/ArrayMetaViewer.vue";
import ObjectMetaViewer from "@/components/MetadataGenerator/TypesViewer/ObjectMetaViewer.vue";
import StringMetaViewer from "@/components/MetadataGenerator/TypesViewer/StringMetaViewer.vue";

export default {
  name: 'MetadataViewer',
  components: {
    ArrayMetaViewer,
    ObjectMetaViewer,
    StringMetaViewer
  },
  props: {
    metadata: {
      required: true
    }
  },
  data() {
    return {
      components: {
        array: 'ArrayMetaViewer',
        object: 'ObjectMetaViewer',
        string: 'StringMetaViewer',
      }
    }
  },
  computed: {
    selectedComponent() {
      return this.components[this.metadata.type]
    }
  },
  methods: {
    handleEditStringMetaViewer(newValue) {
      this.metadata.value = newValue
      this.$emit('edit', this.metadata)
    },
    handleEditObjMetaViewer(newValue) {
      this.metadata.value = newValue
      this.$emit('edit', this.metadata)
    },
    handleEditListMetaViewer(newValue) {
      this.metadata.value = newValue
      this.$emit('edit', this.metadata)
    }
  }
}
</script>