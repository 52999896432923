<template>
  <v-col :cols="cols">
    <div class="row">
      <div class="msg-content">
        <p>
          {{ label }}
        </p>
      </div>
      <div class="check-buttons">
        <CheckBox @input:value="check1 = $event" :valueField="check1" mensagem="Aceitar" color="primary" img="las la-check"/>
        <CheckBox @input:value="check2 = $event" :valueField="check2" mensagem="Recusar" color="red" img="las la-times"/>
      </div>
    </div>
  </v-col>

</template>


<script>
import CheckBox from "./CheckBox";

  export default {
    name: 'AccpetOrRefuseButton',
    props: {
      valueField: {
        Type: Boolean,
        Default: false
      },
      label: {
        Type: String
      },
      cols: {}
    },
    data() {
      return {
        check1: false,
        check2: true,
        classe: 'default',
        accept: 'primary',
        // button: ,
        accept_: "accept",
        return_object: {}
      }
    },
    components: {
      CheckBox
    },
    methods: {},
    mounted() {
      // this.check1 = this.valueField
    },
    watch: {
      valueField(value) {
        this.check1 = value
      },
      check1() {
        if (this.check1) {
          this.check2 = false
        }else {
          this.check2 = true
        }
        this.$emit('update:value', this.check1)
      },
      check2() {
        if (this.check2) {
          this.check1 = false
        }else {
          this.check1 = true
        }
      }
    }
  }
</script>

<style scoped>

.check-buttons {
  display: flex;
}

.row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.msg-content {
  display: flex;
  flex-display: row;
  justify-content: center;
  align-items: center;
}

p {
  width: 500px;
  font-size: 18px;
  margin: 0;
  margin-left: 7px;
}

</style>