import { render, staticRenderFns } from "./ModalMaisDetalhes.vue?vue&type=template&id=2057cc20&scoped=true&"
import script from "./ModalMaisDetalhes.vue?vue&type=script&lang=js&"
export * from "./ModalMaisDetalhes.vue?vue&type=script&lang=js&"
import style0 from "./ModalMaisDetalhes.vue?vue&type=style&index=0&id=2057cc20&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2057cc20",
  null
  
)

export default component.exports