<template>
  <div class="menu">
    <v-card rounded="0">
      <v-navigation-drawer
          permanent
          :mini-variant.sync="mini"
          color="primary"
      >
        <v-list class="list-img" style="background-color: white">
          <v-list-item class="pa-0">
            <img class="imagem" alt="Vue logo" src="../../assets/logo_smarti.png">
          </v-list-item>
        </v-list>
        <v-list
            nav
            dense
        >
          <v-list-item-group
            class="list-item-group"
            active-class="testinho"
          >
            <v-list-item @click.native="$router.push({name: 'Dashboard'}).catch(() => {})" link>
              <v-list-item-icon>
                <v-icon color="white">mdi-view-dashboard-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="cor-texto">Dashboard</v-list-item-title>
            </v-list-item>

            <v-list-item @click.native="$router.push({ name: 'Executions'}).catch(() => {})" link>
              <v-list-item-icon>
                <v-icon color="white">mdi-play-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="cor-texto">Executions</v-list-item-title>
            </v-list-item>

            <v-list-item @click.native="$router.push({name: 'Workflow'}).catch(() => {})" link :v-show="onlyAdminMaster || onlyAdmin">
              <v-list-item-icon>
                <v-icon color="white">mdi-format-list-bulleted-square</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="cor-texto">Workflow</v-list-item-title>
            </v-list-item>

            <v-list-item @click.native="$router.push({name: 'Users'}).catch(() => {})" link :v-show="onlyAdminMaster || onlyAdmin">
              <v-list-item-icon>
                <v-icon color="white">mdi-account-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="cor-texto">Usuários</v-list-item-title>
            </v-list-item>

            <v-list-item @click.native="logout()" link>
              <v-list-item-icon>
                <v-icon color="white">mdi-arrow-u-left-bottom</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="cor-texto">Sair</v-list-item-title>
            </v-list-item>

          </v-list-item-group>
        </v-list>

      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
import axios from '../../plugins/axios'

export default {
  name: 'MenuBar',
  data() {
    return {
      mini: false,
      onlyAdminMaster: localStorage.user_type == 1 ? true : false,
      onlyAdmin: localStorage.user_type == 2 ? true : false,
    }
  },
  props: {
    menu: {
      Type: Boolean,
      Default: false
    }
  },
  mounted() {
    this.mini = this.menu
  },
  watch: {
    mini : {
      handler(){
        this.$emit('mini', this.mini)
      }
    },
    menu: {
      handler(){
        this.mini = this.menu;
      }
    }
  },
  methods:{
    logout() {
      axios.get('/logout', {
        headers: {
          "Authorization": localStorage.access_token,
        },
      })
        .then(response => {
          this.$router.push({ name: 'Login' }).catch(() => { })
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }
}

</script>


<style scoped>

.list-item-group {
  /*margin-top: 6rem;*/
  background-color: #0d4e79 !important;
}

.v-item-group{
  background-color: #0d4e79 !important;
}

.v-list-item--active {
  background-color: #0d4e79 !important;
}

v-card {
  border-radius: 0;
}


.cor-texto {
  color: white;
}

.menu {
  height: 100%;
  display: flex;
}

.text {
  display: flex;
  margin-left: 10%;
  color: white;
}

.titulo {
  display: flex;
  flex-direction: row;
}

.imagem {
  width: 100%;
  padding: 0em 0.2em 0.2em 0.2em;
  webkit-transition: width 1s;
  transition: width 1s;
}

.list-img {
  height: 200px;
  background-color: white !important;
  display: flex;
  justify-content: center;

}

.divider {
  background-color: #002E5D;
  margin-top: 20px;
}
</style>