<template>
  <v-app class="app">
    <router-view/>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";

export default {

  name: 'App',
  components: {

  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["loading"])
  }
};
</script>

<style>

@import "./assets/fonts/line-awesome-1.3.0/1.3.0/css/line-awesome.min.css";
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');


v-app {
  font-family: Verdana;
}

*{
  font-family: Verdana;
  scroll-behavior: auto !important;
  scroll-behavior: smooth !important;
  scrollbar-width: thin;
}


font {
  font-family: Verdana;
}


/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #7c7c7c;
  border-radius: 20px;
  border: 3px solid #eaeaea;
}

.app {
  width: 100%;
  height: 100%;
  font-family: Verdana, sans-serif;
  scroll-behavior: auto !important;
  scroll-behavior: smooth !important;
}

nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

}

.navbar-links {
  display: flex;
  flex-direction: row;
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
li {
  list-style: none;
}

nav {
  position: relative;
}

.pagination {
  padding: 25px;
  /*display: flex;*/
  width: 100%;
  justify-content: center;
}

.nav-link > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4b3e91;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: +1;
  align-items: center;
  opacity: 1 !important;
  box-shadow: 0 0 10px black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
