<template>
   <div class="users">
      <div class="titulo text-center elevation-1">
         <h3>Usuários</h3>
      </div>

      <div v-if="dialogLoading">
         <v-dialog v-model="dialogLoading" max-width="200px">
            <v-card>
               <v-card-title class="headline text-center">Carregando...</v-card-title>
               <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-icon class="mdi-spin">mdi-loading</v-icon>
                  <v-spacer></v-spacer>
               </v-card-actions>
            </v-card>
         </v-dialog>
      </div>
      
      <div class="mx-2 mb-8" style="width:100%">
         <v-dialog transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
               <v-btn color='gray' v-bind="attrs" v-on="on" id="btn-add-user"
                  @click="openDialog()" block elevation="2" large>
                  ADD USER
               </v-btn>
            </template>
            <template v-slot:default="dialog">
               <v-card>
                  <v-toolbar color="primary" dark>{{titleUser()}}</v-toolbar>
                  <v-card-text>
                     <div class="pa-5">
                        <v-form ref="form">
                           <v-text-field 
                              class="my-5" label="Nome" 
                              :rules="name_rules"
                              v-model="editedItemUser.name"
                              hide-details="auto"/>
                           <v-text-field 
                              class="my-5" 
                              label="Nome de Usuário" 
                              :rules="username_rules"
                              v-model="editedItemUser.username" 
                              hide-details="auto"/>
                           <v-text-field 
                              class="my-5" 
                              label="Email" 
                              :rules="email_rules"
                              v-model="editedItemUser.email"
                              hide-details="auto"/>
                           <v-select 
                              v-show="user_type == 1"
                              v-model="editedItemUser.type"
                              :items="typesMaster"
                              item-text="name"
                              item-value="code"
                              label="Tipo"
                              class="my-5" 
                              hide-details="auto"/>
                           <v-select 
                              v-show="user_type == 0 || user_type == 2"
                              v-model="editedItemUser.type" 
                              :items="types" 
                              item-text="name" 
                              item-value="code" 
                              label="Tipo" 
                              class="my-5"
                              hide-details="auto" />
                           <v-text-field 
                              v-show="!isEdit"
                              class="my-5" 
                              label="Senha" 
                              :rules="password_rules"
                              hide-details="auto"
                              v-model="editedItemUser.password" 
                              :type="show1 ? 'text' : 'password'" 
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                              @click:append="show1 = !show1"/>
                           <v-text-field 
                              v-show="!isEdit"
                              class="my-5" 
                              label="Confirmar Senha" 
                              :rules="password_confirmation_rules" 
                              hide-details="auto"
                              v-model="editedItemUser.password_confirmation"
                              :type="show2 ? 'text' : 'password'" 
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" 
                              @click:append="show2 = !show2"/>
                        </v-form>
                     </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                     <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                     <v-btn @click="dialog.value = false; isEdit ? editUser() : addUser()">{{isEdit ? 'Editar' : 'Salvar'}}</v-btn>
                  </v-card-actions>
               </v-card>
            </template>
         </v-dialog>
      </div>

      <div class="card-icon elevation-1">
         <div class="table-responsive">
            <v-data-table
            :headers="headers"
            :items="user_type == 1 ? usersMaster : users"
            :sort-by="'id'"
            :items-per-page="15"
            hide-default-footer
            class="elevation-1 estilo-tabela">
            
               <template v-slot:top>
                  <v-dialog v-model="dialogDeleteUser" max-width="520px">
                     <v-card>
                     <v-card-title class="headline">Tem certeza que quer excluir esse usuário?</v-card-title>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDeleteUser">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteUserConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                        </v-card-actions>
                     </v-card>
                  </v-dialog>
               </template>

               <template v-slot:item.type="{ item }">
                  <div v-if="item.type == 0">USUÁRIO</div>
                  <div v-if="item.type == 1">ADMIN MASTER</div>
                  <div v-if="item.type == 2">ADMIN</div>
               </template>

               <template v-slot:no-data>
                  <h4 class="mb-1 mt-1">Nenhum usuário castrado!</h4>
               </template>

               <template v-slot:item.opcoes="{ item }">
                  <v-icon class="mr-2" @click="getEditUser(item)">
                     mdi-pencil
                  </v-icon>
                  <v-icon class="mr-2" @click="deleteUser(item)">
                     mdi-delete
                  </v-icon>
               </template>

            </v-data-table>
         </div>
       </div>

   </div>
</template>

<script>
import axios from '../../plugins/axios'
//import UsuarioNovo from '../Users/UsuarioNovo'
import moment from "moment";

export default {
   name: 'Users',
   components: {
      //UsuarioNovo
   },
   data(){
       return{
         loading: false,
         usuarioNew: "usuarioNovo",
         tableKey: 0,
         isEdit: false,
         dialog: false,
         dialogDeleteUser: false,
         dialogLoading: true,
         senha: '',
         show1: false,
         show2: false,
         user_type: localStorage.user_type == 1,
         typesMaster: [
             {
                name: 'USUÁRIO',
                code: 0,
             },
            {
               name: 'ADMIN',
               code: 2,
             },
             {
                name: 'ADMIN MASTER',
                code: 1,
             },
            
          ],
          types: [
             {
                name: 'USUÁRIO',
                code: 0,
             },
             {
                name: 'ADMIN',
                code: 2,
             },
          ],
         users: [],
         usersMaster: [],
         editedItemUser: {
            id: '',
            name: '',
            username: '',
            type: '',
            email: '',
            password: '',
            password_confirmation: '',
          },
         editedIndexUser: '',
         headers: [
            {
               text: "ID",
               sortable: true,
               filterable: true,
               value: "id",
               align: "center",
            },
            {
               text: "Nome",
               sortable: true,
               filterable: true,
               value: "name",
               align: "rigth",
            },
            {
               text: "Usuário",
               sortable: true,
               filterable: true,
               value: "username",
               align: "rigth",
            },
            {
               text: "E-mail",
               sortable: true,
               filterable: true,
               value: "email",
               align: "rigth",
            },
            {
               text: "Tipo",
               sortable: true,
               filterable: true,
               value: "type",
               align: "center",
            },
            {
               text: "Opções",
               sortable: true,
               filterable: true,
               value: "opcoes",
               align: "center",
            },
         ],
         name_rules: [
            value => !!value || 'Informe seu nome completo.',
            value => (value || '').length <= 80 || 'Limite de 80 caracteres',
            value => {
               const pattern = /[A-Z].[a-z]* [A-z].[a-z]*/
               return pattern.test(value) || 'Nome inválido.'
            },
         ],
          username_rules: [
             value => !!value || 'Informe um usuário.',
             value => (value || '').length >= 5 || 'Mínimo de 5 caracteres',
             value => (value || '').length <= 30 || 'Limite de 30 caracteres',
             value => {
                const pattern = /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9.@_]+(?<![_.])$/
                return pattern.test(value) || 'Usuário inválido.'
             },
         ],
         email_rules: [
            value => !!value || 'Informe um e-mail.',
            value => {
               const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
               return pattern.test(value) || 'E-mail inválido.'
            },
         ],
         password_rules: [
            value => !!value || 'Informe uma senha.',
            value => (value || '').length >= 6 || 'Mínimo de 6 caracteres',
            value => (value || '').length <= 20 || 'Limite de 20 caracteres',
         ],
         password_confirmation_rules: [
            value => !!value || 'Informe uma senha.',
            value => (value || '') == this.editedItemUser.password || 'As senhas não estão iguais.',
         ],
       }
   },

   mounted() {
        this.getUsers();
    },

   watch: {
    },
    
   methods: {
      getUsers(){
         axios.get('/users', {
            headers: {
               "Authorization": localStorage.access_token,
            },
         })
            .then(response => {
               this.users = []
               this.usersMaster = response.data.data
               for(var i=0; i<this.usersMaster.length; i++){
                  if (this.usersMaster[i].type != 1) {
                     this.users.push(this.usersMaster[i])
                  }
               }
               this.dialogLoading = false
            })
            .catch(error => error)
       },
      refreshTable() {
         this.tableKey++;
      },

      openDialog() {
         this.isEdit = false
         this.dialog = true
         this.$refs.form.reset()
      },
      addUser() {
         this.dialogLoading = true
         var dados = {
            name: this.editedItemUser.name,
            username: this.editedItemUser.username,
            type: this.editedItemUser.type,
            email: this.editedItemUser.email,
            password: this.editedItemUser.password,
            password_confirmation: this.editedItemUser.password_confirmation,
         }
         axios
            .post('/signup', dados, {
               headers: {
                  "Authorization": localStorage.access_token,
               },
            })
            .then(response => {
               this.getUsers()
            })
            .catch(error => {
               console.log(error);
            });
      },
      getEditUser(item) {
         document.getElementById("btn-add-user").click()
         this.isEdit = true;
         this.editedItemUser.name = item.name;
         this.editedItemUser.type = item.type;
         this.editedItemUser.username = item.username;
         this.editedItemUser.email = item.email;
         this.editedItemUser.id = item.id;
         //this.editedItemUser.password = item.password;
         //this.editedItemUser.password_confirmation = item.password_confirmation;
      },
      editUser() {
         this.dialogLoading = true
         var dados = {
            id: this.editedItemUser.id,
            name: this.editedItemUser.name,
            username: this.editedItemUser.username,
            email: this.editedItemUser.email,
            type: this.editedItemUser.type,
         };
         axios
            .put('/users/' + dados.id, dados, {
               headers: {
                  "Authorization": localStorage.access_token,
               },
            })
            .then(response => {
               this.getUsers()
            })
            .catch(error => {
               console.log(error);
            });
      },
      titleUser(){
         return this.isEdit ? 'Editar Usuário' : 'Novo Usuário'
      },
      deleteUser(item) {
         this.editedIndexUser = this.users.indexOf(item)
         this.editedItemUser = Object.assign({}, item)
         this.dialogDeleteUser = true
      },
      deleteUserConfirm() {
         this.dialogLoading = true
         axios
            .delete(
               "/users/" + this.users[this.editedIndexUser].id, {
               headers: {
                  "Authorization": localStorage.access_token,
               },
            })
            .then(() => {
               this.getUsers()
            })
            .catch((error) => {
               console.log(error);
            });
         this.closeDeleteUser()
      },
      closeDeleteUser() {
         this.dialogDeleteUser = false
         this.$nextTick(() => {
            this.editedItemUser = Object.assign({}, this.defaultItemUser)
            this.editedIndexUser = -1
         })
      },
   }
}
</script>

<style>
.users{
   display: flex;
   flex-wrap: wrap;
   margin-left: 20px;
   margin-right: 20px;
}

.card-chart{
   flex: 1 1 50%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.card-icon{
   flex: 1 1 18%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
   display: flex;
   flex-wrap: wrap;
}

.card-icon .card-icon-body{
   padding: 20px;
}

.card-icon-i{
   width: 90px;
   height: 90px;
   border-radius: 50%;
   background-color:#e5f4e7;
   margin: 0 auto;
   position: relative;
}

.card-icon .card-icon-body h3{
   text-align: center;
   margin-bottom: 0;
   margin-top: 10px;
   font-size: 18px;
   font-weight: 400;
}

.card-icon-i i{
   top: 50%;
   text-align: center;
   position: absolute;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: 45px;
   color: #5DAE8B;
}

.chart{
   flex: 0 1 60%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.table{
   flex: 0 1 58%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.table-responsive {
   display: block;
   width: 100%;
   overflow-x: auto;
}

.table .table-head{
   border-bottom: 1px solid #eee;
   padding: 0 15px;
}

.table .table-body{
   padding: 25px;
}

.table .table-body table{
   width: 100%;
   border-collapse: collapse;
}

.table table thead tr th{
   padding: 0 10px;
   height: 50px;
   vertical-align: middle;
   color: #666;
   font-weight: 500;
   border-bottom-color: #e2e8f0;
   background-color: #f7f8f9;
   border-top-width: 1px;
   border-bottom-width: 1px;
}

.table tbody tr td, .table tbody tr th {
   padding: 10px;
   border-bottom: 1px solid #eee;
   vertical-align: middle;
   font-size: 14px;
   color: #212529;
   text-align: center;
}

.btn-group{
   display: flex;
   align-items: center;
   justify-content: center;
}

.btn-group a{
   padding: 3px;
   width: 26px;
   height: 26px;
       box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
   margin: 0 5px;
       color: #fff;
   background-color:#5dae8b;
   border-color: #5dae8b;
   border-radius: 0.375rem;
   font-weight: 500;
}

.btn-group a{
       text-align: center;
   line-height: 25px;
   display: block;
}

@media only screen and (max-width: 768px) {
   .table{
       flex: 1 1 100%;
       overflow-x: scroll;
   }
}

.titulo {
   padding: 10px;
   flex: 1 1 100%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.active-icon{
    background-color: green;
}

.disabled-icon{
    background-color: gray;
}
</style>
</style>