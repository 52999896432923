<template>
  <v-col class="col-nopadding" :cols="cols">
    <v-select
        color="primary"
        :disabled="disabled"
        :item-text="keyName ? keyName : 'name'"
        :item-value="keyValue ? keyValue : 'value'"
        v-model="select"
        :label="label"
        :items="items"
        outlined
        :rules="rules ? rules : ''"
        :prepend-inner-icon="prependInnerIcon"
        append-icon="la-angle-down"
        @input="$emit('update:value', select)"
        @keyup.enter="$emit('onEnter', select)"
        :hint="hint ? hintLenged : ''"
        :persistent-hint="!!hint"
        return-object
        :dense="dense"
    >
      <template slot="no-data">
        <div class="message-box">
          Nenhuma informação encontrada.
        </div>
      </template>
      <template slot="loading">
        <div class="message-box">
          Carregando
        </div>
      </template>
    </v-select>
  </v-col>
</template>

<script>
export default {
  name: "SelectField",
  props: {
    label: {},
    placeholder: {},
    type: {},
    cols: {},
    prependInnerIcon: {},
    rules: {},
    items: {},
    keyName: {
      default: 'name'
    },
    data: {},
    keyValue: {
      default: 'value'
    },
    disabled: {},
    valueField: {},
    hint: {},
    dense: {
      default: false
    },
  },
  data() {
    return {
      select: {}
    }
  },
  mounted() {
    if (typeof(this.valueField) === 'undefined' && this.items.length > 0) {
      this.select = this.items[0];
      this.$emit('update:value', this.select);
    } else {
      this.select = this.valueField;
      this.$emit('update:value', this.select);
    }
  },
  watch: {
    valueField() {
      this.select = this.valueField;
      this.$emit('update:value', this.select);
    }
  },
  computed: {
    hintLenged() {
      if (this.hint) {
        let string = '';
        this.hint.forEach((legend, index) => {
          string += index % 2 ? ` ${this.select[legend]} | ` : legend;
        });
        return string;
      }
      return '';
    }
  }
}
</script>

<style scoped>

.col-nopadding {
  padding: 0 !important;
}
</style>