<template>
  <div class="ModalMaisDetalhes">
    <div class="header">
      <h2>
        {{ email.classification }}
      </h2>
      <div @click="closeModal" class="botao-fechar">
        <v-icon size="38" color="#3C2E88">
          las la-times-circle
        </v-icon>
      </div>
    </div>
    <div class="divider-header"/>
    <div class="body-email">
      <div class="processed_email" v-for="(e, index) in email.processed_emails_logs" :key="e+index">
        <h4>
          {{ e.id + ' - ' + e.executed_by + ' - ' + e.created_at | horaFormat}}
        </h4>
        <h4>
          Descrição: {{ e.description }}
        </h4>
        <h5>
          Observações:
          <p style="font-size: 0.8rem; font-weight: normal">
            {{ e.observation }}
          </p>
        </h5>
      </div>
    </div>

    <div style="margin-top: 1rem">
      <SelectField
        :items="items"
        keyName="text"
        keyValue="text"
        :rules="[]"
        :valueField="valor_inicial"
        @update:value="valor = $event"

      />
      <div style="display: flex; width: 100%; justify-content: end">
        <RetangularButton
            @click.native="sendClassification()"
            text="Salvar"
            class="retangular-button"
        />
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions} from "vuex";
import RetangularButton from "../Buttons/RetangularButton";
import SelectField from "../inputs/SelectField";

export default {
  name: 'ModalMaisDetalhes',
  filters: {
    horaFormat(value) {
      return value.split('.', 1)[0]
    }
  },
  components: {
    SelectField,
    RetangularButton
  },
  props: {
    item: {},
  },
  created() {
    this.getEmail()
  },
  mounted() {
  },
  data() {
    return {
      email: {},
      items: [
        {
          text: '2ª VIA POR EMAIL',
        },
        {
          text: '2º VIA / DÉBITO SUPERIOR A 60 DIAS',
        },
        {
          text: 'BOLETO PAGO EM DUPLICIDADE',
        },
        {
          text: 'CONFIRMAÇÃO DE PAGAMENTO',
        },
        {
          text: 'NÃO RECONHECIDO',
        },
        {
          text: 'EMPRÉSTIMO',
        },
      ],
      valor: null,
      valor_inicial: null,
      email_reclassification: {},
    }
  },
  methods: {
    ...mapActions(["GET_LOG", "LOG_RECLASSIFICATION"]),
    closeModal() {
      this.$emit('closeModal');
    },

    getEmail() {
      let obj = {
        id: this.item.id
      }
      this.GET_LOG(obj)
          .then(res => {
            this.email = res.data;
            if (res.data.email_reclassification) {
              this.valor_inicial = {
                text: res.data.email_reclassification.email_new_classification
              }
            }else {
              this.valor_inicial = {
                text: res.data.classification
              }
            }
          })
    },

    sendClassification() {
      let obj = {
        created_by: 'admin',
        email_body: this.item.email_body,
        email_id: this.item.email_id,
        email_new_classification: this.valor.text
      }
      this.LOG_RECLASSIFICATION(obj).then(() => {
        this.$emit('closeModal', true);
      })
    }
  },
  computed: {
    // ...mapGetters(["loading"]),
    // teste(string) {
    //   let resultado = string.split(">")
    //   return string.split()
    // }
  }
}
</script>

<style scoped>
.buttons-separator {
  width: 20px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #7c7c7c;
  border-radius: 20px;
  border: 3px solid #eaeaea;
}

.body-email {
  overflow-y: auto;
  max-height: 30rem;
}

.correcao-borda {
  border: 2px solid #3c2e88 !important;
  box-shadow: 3px 4px 18px rgba(99, 88, 160, 0.35);
}

.container-plano h4 {
  font-family: "open-sans", Roboto;
  font-size: 16px;
  margin-bottom: 5px;
}

.regional {
  width: 50px;
  height: 50px;
}

.box-plano {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 50px;
  width: 180px;
}

.texto-valor-plano {
  font-size: 24px !important;
  padding-left: 0px !important;
}

.box-plano p {
  font-family: "campton-semibold";
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  padding-left: 10px;
}


.divider-plano {
  background-color: #E6E7E8;
  height: 87px;
  width: 1px;
}

.botao-fechar:hover {
  background-color: lightgray;
  width: 38px;
  cursor: pointer;
  border-radius: 50%;
  height: 38px;
}

.imagem-texto-fundo h2 {
  color: white;
  font-family: "campton-semibold";
}

.imagem-texto-fundo p {
  color: white;
  font-family: "open-sans", Roboto;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
}

.imagem-texto-fundo {
  width: 100%;
  height: 190px;
  margin-top: 50px;
  mix-blend-mode: multiply;
  border-radius: 10px;
}


.ModalMaisDetalhes {
  margin: 40px;
  overflow-y: visible;
  overflow-x: hidden;
  max-height: 90%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.texto-central {
  width: 100%;
  margin-top: 20px;
  display: flex;
  overflow-y: auto;
  align-items: center;
  height: 7rem;
}

.texto-central > p {
  font-size: 18px;
  font-weight: bolder;
  line-height: 30px;
  max-width: 700px;
  height: 100%;
  font-family: "open-sans", Roboto;
}

.header > h2 {
  font-family: "campton-semibold", Roboto;
  color: #2B2B2B;
  font-size: 25px;
  width: 90%;
}

p {
  font-family: "campton-semibold", Roboto;
  font-size: 0.85rem;
}

h4 {
  font-family: "campton-semibold", Roboto;
  color: #2B2B2B;
  font-size: 0.9rem;
  width: 90%;
}

h5 {
  font-family: "campton-semibold", Roboto;

}

.divider-header {
  background-color: #361ad3;
  height: 0.15rem;
  width: 820px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.processed_email {
  padding: 1rem;
  background-color: #efefef;
  margin-bottom: 1rem;
}

.header > i {
  padding-bottom: 65px;
}

.retangular-button {
  max-width: 9rem;
}
</style>
