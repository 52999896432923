<template>
  <div class="teste">
    <div class="background-image"></div>
      <v-card class="inputs">
        <div class="image-logo">
          <v-img
              width="50px"
              height="150px"
              src="../../assets/logo_smarti.png"
          ></v-img>
        </div>
        <div class="divider"></div>
          <div class="input-item">
            <div>
              <FormFields
                  :fields="fields"
                  :validate="validateFields"
                  @update:values="inputsValue = $event"
                  @checkValidity="sendLogin($event)"
              />
            </div>
            <h5  style="margin: 0; margin-top: -2rem; color: red" v-if="alert">{{ msg }}</h5>
            <div class="buttons">
              <v-btn
                  @click="$router.push({name: 'RedefenirSenha'})"
                outlined
                color="primary"
                width="40%"

              >Redefinir
              </v-btn>
              <v-btn
                outlined
                width="40%"
                @click.native="validate()"
                color="primary"
                >Entrar
              </v-btn>
            </div>
          </div>
      </v-card>

  </div>
</template>

<script>
import FormFields from "../../components/FormFields";
import { mapActions } from "vuex";
import axios from '../../plugins/axios'

  export default {
    name: 'Login',
    components: {
      FormFields,
    },
    data () {
      return {
        inputsValue: {
          username: null,
          password: null
        },
        validateFields: 0,
        alert: false,
        fields: {},
        msg: {},
      }
    },
    mounted() {
      this.initFields()
    },
    methods: {
      ...mapActions(['LOGIN']),

      initFields() {
        localStorage.access_token = '';
        localStorage.user_type = 0;
        this.fields = {
          username: {
            identificacao_interna: 'username',
                field: 'TextField',
                cols: 12,
                label: 'Username',
                dinamicRules: [(v) => !!v || "Preencha o username"],
                disabled: false,
            valueField: this.inputsValue.username,
          },
          password: {
            identificacao_interna: 'password',
                field: 'TextField',
                cols: 12,
                label: 'Senha',
                type: 'password',
                dinamicRules: [(v) => !!v || "Preencha a senha"],
                disabled: false,
                valueField: this.inputsValue.password,
          },
        }
      },

      validate() {
        this.validateFields++;
      },

      sendLogin(event) {
        this.msg = 'Carregando...'
        this.alert = true
        if (event.isValid) {
          console.log(event.values)
          axios
            .post('/login', event.values)
            .then(response => {
              localStorage.access_token = "Bearer " + response.data.access_token;
              localStorage.user_type = response.data.type;
              this.$router.push({ name: 'Dashboard' })
            })
            .catch(error => {
              this.msg = 'Username ou senha incorretos!'
              console.log(error);
            });
        }
      },
    }
  }
</script>

<style scoped lang="scss">
h1 {
  color: #0d4e79;
  margin-top: 70%;
}

.background-image {
  width: 100%;
  height: 100%;
  /*background-image: url(../../../assets/wallpaper.jpg);*/
  background-color: #0d4e79;
  /*filter: blur(8px);*/
  /*-webkit-filter: blur(8px);*/
  /*background-attachment: fixed;*/
  /*background-position: 100%;*/
  background-repeat: no-repeat;
  background-size: cover;

}

.inputs {
  background-color: #fff;
  /*border: 1px solid #3c2e88;*/
  border-radius: 0.5em;
  color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  z-index: 2;
  text-align: center;
  width: 24em;
  height: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.input-item {
  padding: 1em 0 0 0;
  margin-top: 0.5em;
  width: 70%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.image-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /*background-color: black;*/
}

.divider {
  width: 100%;
  border-bottom: 0.4em solid #0d4e79;

}

.teste {
  width: 100%;
  height: 100%;
  display: block;
}

.buttons {
  display: flex;
  flex-display: row;
  justify-content: space-between;
  padding: 0px 0px 50px 0px;
}
</style>
