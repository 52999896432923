<template>
  <div>
    <component
      v-for="(metadata, index) in valueArray"
      :key="index"
      :is="dataToComponent(metadata)"
      :metadata="metadata"
      @editStringMetaViewer="handleEditStringMetaViewer($event, index)"
      @editObjMetaViewer="handleEditObjMetaViewer($event, index)"
    />
  </div>
</template>

<script>
import Metadata from "@/mixins/Metadata.vue";
import ArrayMetaViewer from "@/components/MetadataGenerator/TypesViewer/ArrayMetaViewer.vue";
import ObjectMetaViewer from "@/components/MetadataGenerator/TypesViewer/ObjectMetaViewer.vue";
import StringMetaViewer from "@/components/MetadataGenerator/TypesViewer/StringMetaViewer.vue";

export default {
  name: 'ArrayMetaViewer',
  components: {
    ArrayMetaViewer,
    ObjectMetaViewer,
    StringMetaViewer
  },
  mixins: [
    Metadata
  ],

  props: {
    metadata: {
      required: true
    }
  },
  data(){
    return {
      components: {
        array: 'ArrayMetaViewer',
        object: 'ObjectMetaViewer',
        string: 'StringMetaViewer',
      },
      valueArray: [],
      valueEmit: [],
    }
  },
  mounted(){
    this.valueArray = this.arrayToMetaObject(this.metadata.value)
    for (let i = 0; i < this.valueArray.length; i++) {
      this.valueEmit.push(this.valueArray[i].value)
    }
  },
  methods: {
    dataToComponent(data){
      return this.components[data.type]
    },
    handleEditStringMetaViewer(newValue, index) {
      this.valueEmit[index] = newValue
      this.$emit('editListMetaViewer', this.valueEmit)
    },
    handleEditObjMetaViewer(newValue, index) {
      this.valueEmit[index] = newValue
      this.$emit('editListMetaViewer', this.valueEmit)
    },
  },
}
</script>
