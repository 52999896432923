<template>
  <div class="content-button">
    <div class="estrutura" :class="button_class">
      <div @click="select" class="button-type" type="button">
        <v-icon :color="icon_class" :class="img" />
      </div>
    </div>
    <p>{{ mensagem }}</p>
  </div>
</template>

<script>

  export default {
    name: 'CheckBox',
    props: {
      classe: {},
      img: {},
      mensagem: {},
      color: {
        default: 'verde',
        type: String
      },
      valueField: {
        default: false,
        type: Boolean
      },
    },
    data(){
      return {
        isSelected: false,
      }
    },
    mounted(){
      this.isSelected = this.valueField;
    },
    methods: {
      select() {
        this.isSelected = !this.isSelected;
        this.$emit('input:value', this.isSelected)
      }
    },
    computed: {
      button_class(){
        if(this.isSelected){
          return this.color;
        }

        return '';
      },
      icon_class(){
        if(this.isSelected){
          return 'white';
        }

        return this.color + '_icon';
      }
    },
    watch: {
      valueField() {
        this.isSelected = this.valueField
      }
    }
  }
</script>


<style scoped>

  .button-type {
    max-width: 20px;
    max-height: 20px;
    display: flex;
    justify-content: center;
  }

  .verde {
    background-color: green;
  }

  .vermelho {
    background-color: red;
  }

  .estrutura {
    width: 30px;
    height: 30px;
    border: 0.5px solid #00478D;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filled {
    width: 30px;
    height: 30px;
    border: 0.5px solid #00478D;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00478D;
    color: white;
  }

  .refuse-button{
    width: 30px;
    height: 30px;
    border: 0.5px solid #fa4343;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 130px;
  }

  p {
    margin: 0;
    margin-left: 7px;
  }

  .row-content {
    display: flex;
    flex-direction: row;
    width: 220px;
    justify-content: space-between;
  }


</style>