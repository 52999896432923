<template>
  <div>
    <input v-model="metadata.value" type="text" style="width: 70%; padding:5px;"></input>
  </div>
</template>

<script>
export default {
  name: 'StringMetaViewer',
  props: {
    metadata: {
      required: true
    }
  },
  watch: {
    'metadata.value': function (val) {
      this.$emit('editStringMetaViewer', val)
    }
  }
}
</script>
