<template>
  <div>
    <div class="border" v-if="keys.length > 0">
      <div v-for="(key, index) in keys" :key="index"> 
        {{ key }}:
        <input v-model="metadata.value[key]" type="text" style="width: 70%; padding:5px;"></input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectMetaViewer',
  props: {
    metadata: {
      required: true
    },
  },
  watch: {
    'metadata.value': {
      deep: true,
      handler(newVal) {
        console.log('metadata foi alterado:', newVal);
        this.$emit('editObjMetaViewer', newVal)
      }
    }
  },
  data(){
    return {
      keys: []
    }
  },
  mounted(){
    this.keys = Object.keys(this.metadata.value)
  }
}
</script>

<style scoped>
.border {
  border: 1px solid rgb(181, 181, 181);
  padding: 5px;
  margin: 5px;
}
</style>
