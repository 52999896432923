import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from "./plugins/axios";
import VModal from "vue-js-modal";
import 'vuetify/dist/vuetify.min.css'
// import './assets/scss/app.scss'
import VueMask from "v-mask";
import VueApexCharts from 'vue-apexcharts'

Vue.prototype.$http = axios
// Sets the default url used by all of this axios instance's requests

//Alterar a url abaixo para puxar a o link da API
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.get['Accept'] = 'application/json'

Vue.use(VModal)
Vue.use(VueMask)
Vue.use(VueApexCharts)
Vue.config.productionTip = false
Vue.component('apexchart', VueApexCharts)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {

  if (to.name == 'Workflow'){
    if (localStorage.user_type != 1 && localStorage.user_type != 2) {
      next('/Dashboard');
    }
  }
  
  if (to.name == 'Users') {
    if (localStorage.user_type != 1 && localStorage.user_type != 2) {
      next('/Dashboard');
    }
  }

  next()
});
