<template>
  <div class="workflow">

    <div v-if="dialogLoading">
      <v-dialog v-model="dialogLoading" max-width="200px">
        <v-card>
          <v-card-title class="headline text-center">Carregando...</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-icon class="mdi-spin">mdi-loading</v-icon>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-btn
      id="btn-atualizar"
      color="primary"
      class="ma-2 white--text hidden"
      @click= refresh++
      >
    </v-btn>

    <div class="titulo text-center elevation-1">
      <h3>Executions</h3>
    </div>

    <div class="executions">

      <div class="div-set-workflow">
        <v-card class="card-workflows-title">
            Selecione o workflow
          </v-card>

        <v-card class="div-list-workflows">
          <v-card v-for="workflow in workflowsList" 
          :key="workflow.id" 
          class="card-workflows"
          :class="{ 'card-active': workflow.id === idWorkflowSelected }"
          @click="setWorkflowID(workflow)"
          >
            <div v-if="workflow.status =='enabled'" class="card-icon-i">
              <v-icon> mdi-check-bold </v-icon>
            </div>
            <div v-else class="card-icon-i disabled-icon">
              <v-icon> mdi-close-thick </v-icon>
            </div>
            {{ workflow.description }}
          </v-card>
        </v-card>
      </div>
      
      <div class="div-data-table">
        <v-card class="card-title-workflow">
          <div class="title-workflow">{{ descriptionWorkflowSet }}</div>
          <div class="icon-cancel-all-executions">
            <v-icon
              class="mr-2 icon_acoes"
              :disabled="true"
              color='red'
              title="Cancelar todas as execuções deste workflow"
              @click="cancelAll()">
              mdi-cancel
              </v-icon>
          </div>
        </v-card>
        <div class="data-table">
          <div class="card-table">
            <v-data-table
              :headers="onlyAdmMaster ? headersAdmin : header"
              :items="item.data"
              :page="page"
              :items-per-page="45"
              :sort-by="'id'"
              :sort-desc= true
              hide-default-footer
              hide-default-header
              class="elevation-1"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              show-expand
            >

              <template v-slot:header="{ props: { headers } }">
                <thead>
                <tr>
                  <th v-for="(h, index) in headers" :key="h+index" class="test">
                    <h4 style="text-align: center">{{h.text}}</h4>
                  </th>
                </tr>
                </thead>
              </template>

              <template v-slot:item.avatar="{ item }">
                <div class="buttons">
                  <v-icon
                      size="24"
                      color= "primary"
                  >
                    mdi-play-box-outline
                  </v-icon>
                </div>
              </template>

              <template v-slot:item.startTime="{ item }">
                {{ formataData(item.initial) }}
              </template>

              <template v-slot:item.time="{ item }">
                {{ calculaTempo(item.initial,item.final) }}
              </template>

              <tebuttonsmplate v-slot:item.status="{ item }">
                <v-chip
                  :color="getColor(item.status)"
                  dark
                >
                  {{ item.status }}
                </v-chip>
              </tebuttonsmplate>

              <template v-slot:item.actions="{ item }">
                <v-icon
                class="mr-2 icon_acoes"
                :disabled="item.status!='canceled' && item.status!='error'"
                color='green'
                title="Recuperar"
                @click="recover(item.id)">
                mdi-play-circle-outline
                </v-icon>

                <v-icon
                class="mr-2 icon_acoes"
                :disabled="item.status!='running'"
                color='red'
                title="Cancelar"
                @click="cancel(item.id)">
                mdi-cancel
                </v-icon>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-6">
                  <v-data-table
                    :headers="headersNodes"
                    :items="item.node_execution_not_break"
                    :sort-by="'id'"
                    :items-per-page="999"
                    hide-default-footer
                    class="elevation-1 estilo-tabela">

                    <template v-slot:no-data>
                      <h4 class="mb-1 mt-1">Este workflow não possui nodes!</h4>
                    </template>

                    <template v-slot:item.avatar="{ item }">
                      <div class="buttons">
                        <v-icon
                          size="24"
                          color= "primary"
                        >
                          mdi-file-tree
                        </v-icon>
                      </div>
                    </template>

                    <template v-slot:item.actions="{ item }">
                    
                      <v-row justify="space-around" style='max-width:90%;'>
                    
                        <v-col cols="auto">
                            <v-dialog scrollable transition="dialog-top-transition" width="800">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2 icon_acoes" color='gray' title="Informações" v-bind="attrs" v-on="on"
                                  @click="jsonInfo(item)" v-show="item.data">
                                  mdi-magnify
                                </v-icon>
                              </template>
                              <template v-slot:default="dialog">
                                  <v-card>
                                    <v-toolbar color="primary" dark>Informações node {{item.id}}</v-toolbar>
                                    <v-card-text>
                                      <div class="pa-5">
                                        <div style="color:black;">
                                          <pre>{{ jsonPrint }}</pre>
                                        </div>
                                      </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                      <v-btn text @click="dialog.value = false;">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                              </template>
                            </v-dialog>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:item.status="{ item }">
                      <v-chip
                        :color="getColor(item.status)"
                        dark
                      >
                        {{ item.status }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>

            <p></p>

            <div class="text-center pt-2">
              <v-pagination
                  class="pagination"
                  v-model="page"
                  :length="pageCount"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>

    </div>
    <v-alert
        class="alert"
        border="top"
        colored-border
        type="info"
        elevation="2"
        :value="alert"
        color="primary"
    >
      Alteração salva! Enviado para treinamento do robô.
    </v-alert>
  </div>
</template>

<script>

import ModalMaisDetalhes from "../../components/RegistrosEmail/ModalMaisDetalhes";
import GenericDataTable from "../../components/DataTable/GenericDataTable";
import Header from "../../components/Menu/Header";
import {mapActions} from "vuex";
import axios from '../../plugins/axios'
import moment from "moment";

export default {
  name: 'Dashboard',
  components: {
    Header,
    ModalMaisDetalhes,
    GenericDataTable,
  },

  data() {
    return {
      onlyAdmMaster: localStorage.user_type==1?true:false,
      item: '',
      alert: false,
      dialog: false,
      dialogLoading: true,
      dialogDelete: false,
      idWorkflowSelected: 0,
      response_data: '',                  // Armazenamento temporário de 
      wf_description: '',                 // todos os inputs exigidos no
      wf_root_node_id: null,              // dialog de criação
      wf_trigger_frequency: "* * * * *",  //
      wf_version: '',                     //
      wf_status: '',                      //
      wf_metadata: '',                    //
      wf_id: '',
      itemDelete: '',
      descriptionWorkflowSet: '',
      workflow_data: [],
      // general_rules: [
      //   value => !!value || 'Required.',
      // ],
      
      header: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "ID",
          sortable: true,
          filterable: true,
          value: "id",
          align: "center",
        },
        {
          text: "Descrição",
          sortable: true,
          filterable: true,
          value: "description",
          align: "left",
        },
        {
          text: "Início",
          sortable: true,
          filterable: true,
          value: "startTime",
          align: "center",
        },
        {
          text: "Tempo de execução",
          sortable: true,
          filterable: true,
          value: "time",
          align: "center",
        },
        {
          text: "Situação",
          sortable: true,
          filterable: true,
          value: "status",
          align: "center",
        },
        {
          text: "Nodes",
          sortable: false,
          filterable: false,
          value: "data-table-expand",
          align: "center",
        },
      ],
      headersAdmin: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "ID",
          sortable: true,
          filterable: true,
          value: "id",
          align: "center",
        },
        {
          text: "Descrição",
          sortable: true,
          filterable: true,
          value: "description",
          align: "left",
        },
        {
          text: "Início",
          sortable: true,
          filterable: true,
          value: "startTime",
          align: "center",
        },
        {
          text: "Tempo de execução",
          sortable: true,
          filterable: true,
          value: "time",
          align: "center",
        },
        {
          text: "Situação",
          sortable: true,
          filterable: true,
          value: "status",
          align: "center",
        },
        {
          text: "Nodes",
          sortable: false,
          filterable: false,
          value: "data-table-expand",
          align: "center",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          filterable: false,
          value: "actions",
        },
      ],
      headersNodes: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "ID",
          sortable: true,
          filterable: true,
          value: "id",
          align: "center",
        },
        {
          text: "Nome",
          sortable: true,
          filterable: true,
          value: "name",
          align: "left",
        },
        {
          text: "Fila",
          sortable: true,
          filterable: true,
          value: "workr_queue",
          align: "center",
        },
        {
          text: "Tempo",
          sortable: true,
          filterable: true,
          value: "duration",
          align: "center",
        },
        {
          text: "Status",
          sortable: true,
          filterable: true,
          value: "status",
          align: "center",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          filterable: false,
          value: "actions",
        },
      ],
      itemInfo:{
        code: '',
        nome: '',
        preco: '',
        message: '',
      },
      json: '',
      jsonPrint: [],
      itemData: null,
      workflowsList: {},
      refreshTable: 0,
      refresh: 0,
      page: 1,
      pageCount: 1,
      expanded: [],
      singleExpand: true,
    }
  },

  mounted() {
    this.getWorkflows();
    this.refresh++;
  },

  watch:{
    refresh(){
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.getExecutions();
      }, 5000)
    },
    dialog(){
      if(this.dialog==true){
        this.jsonInfo(this.itemData)
      }
    },
    page() {
      this.dialogLoading = true
      this.getExecutions();
    }

  },

  methods: {
    ...mapActions(["GET_EXECUTIONS", "GET_WORKFLOW"]),
    getExecutions() {
      let filters = {
        per_page: this.perPage,
        page: this.page,
        id: this.idWorkflowSelected,
      }
      this.GET_EXECUTIONS(filters).then(res => {
        this.item = res.data
        this.pageCount=res.data.meta.last_page
        this.dialogLoading = false
        document.getElementById('btn-atualizar').click()
      })
    },

    getWorkflows() {
      this.GET_WORKFLOW().then(res => {
        this.workflowsList = res.data.data
        this.idWorkflowSelected = res.data.data[0].id;
        this.descriptionWorkflowSet = res.data.data[0].description;
        this.getExecutions();
      })
    },

    setWorkflowID(workflow) {
      this.idWorkflowSelected = workflow.id;
      this.descriptionWorkflowSet = workflow.description;
      this.dialogLoading = true;

      this.getExecutions();
    },

 
    show(item) {
      this.item = item
      this.$modal.show('email-modal');
    },

    hide(value) {
      this.$modal.hide('email-modal');
      if(value){
        this.alert = true;
        this.refreshTable++;
        window.setTimeout(() => {
          this.alert = false;
        }, 2000)
      }
    },

    jsonInfo(item) {
      if (item.data == null) {
        this.jsonPrint = {}
      } else {
        this.json = JSON.parse(item.data)
        this.jsonPrint = {}
        //this.loopJson(this.json)
        this.jsonPrint = JSON.stringify(JSON.parse(item.data), null, 2)
        }
    },

    getColor (info) {
      if (info == 'finished') return 'green'
      else if (info == 'executing') return 'blue'
      else if (info == 'running') return 'blue'
      else if (info == 'iterating') return 'blue'
      else if (info == 'on_queue') return 'orange'
      else if (info == 'canceled') return 'black'
      else if (info == 'error') return 'red'
      else if (info == 'recovered') return 'gray'
      else return 'black'
    },

    formataData(data_evento){
      return moment(String(data_evento)).format('DD/MM/YYYY HH:mm:ss')
    },

    calculaTempo(inicial,final){
      if(final == null){
        final = moment()
      }
      let tempo = moment.duration(moment(final).diff(moment(inicial)))

      const duration = moment.duration(tempo);
      const timeString = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');

      return timeString
    },

    recover(id) {
      this.dialogLoading = true
      axios.post('/execution/recover/' + id, null,{
        headers: {
          "Authorization": localStorage.access_token,
        },
      })
                    .then(response => {
                      // this.getExecutions();
                    })
                    .catch(error => error)
      },

      cancel(id){
      this.dialogLoading = true
      axios.post('/execution/cancel/' + id, null, {
        headers: {
          "Authorization": localStorage.access_token,
        },
      })
                    .then(response => {
                      // this.getExecutions();
                    })
                    .catch(error => error)
    },

    cancelAll() {
      
    }

  }

}
</script>


<style scoped>
.workflow{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
}

.executions{
  width: 100%;
  display: flex;
  margin-left: 10px;
}

.div-data-table{
  width: 80%;
}

.card-title-workflow{
  display: flex;
  padding : 10px;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
}

.icon-cancel-all-executions{
  width: 10%;
  justify-content: right;
  align-items: right;
}
.title-workflow{
  width: 90%;
  justify-content: right;
  align-items: right;
}

.data-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
}

.div-set-workflow{
  padding-right: 15px;
  width: 20%;
}

.div-list-workflows{
  height: 85vh;
  overflow: auto;
}

.card-workflows-title{
  padding : 10px;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.card-workflows {
  display: -webkit-box;
  padding: 10px;
  padding-right: 46px;
  margin-bottom: 1px;
}

.card-workflows:hover{
  background-color: #90b3cb;
  cursor: pointer;
}

.card-active{
  background-color: #0d4e79 !important; 
  color: white !important;
}

.alert {
  display: flex;
  position: fixed;
  width: 72%;
  margin-top: 46%
}

.card-table {
  width: 100%;
  flex: 1 1 100%;
  border-radius: 6px;
}

.titulo {
  background-color: rgb(255, 255, 255);
  justify-content: center;
}

.hidden{
  display: none;
}

.card-icon-i{
   width: 35px;
   height: 17px;
   border-radius: 50%;
   margin: 3px 10px 0px 0px;
   
   background-color:#5DAE8B;
}

.card-icon-i i{
   top: 50%;
   text-align: center;
   position: absolute;
   left: 52%;
   font-size: 13px;
   color: #ffffff;
}

.disabled-icon{
    background-color: gray;
}
</style>