import axios from '../../plugins/axios'


export const executionStore = {
    state: () => ({
        executions: [],
        execution: {},
    }),
    mutations: {
        setExecution(state, payload) {
            state.execution = payload;
        },
        setExecutions(state, payload) {
            state.executions = payload;
        },
    },
    actions: {
        GET_EXECUTIONS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get('/execution_by_workflow/' + filters.id + '?page=' + filters.page ,{
                    headers: {
                                "Authorization": localStorage.access_token,
                            },
                }) 
                    .then(response => {
                        let values = response.data
                        commit('setExecutions', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
    },
    getters: {}
}

export default executionStore;