<template>
  <div class="header">
    <div class="mt-5" style="display: flex; justify-content: space-between; width: 90%;">
      <div style="width: 100%;">
        <v-btn
            class="ma-2 white--text"
            color="primary"
            :disabled="iniciar"
            @click="iniciar_log"
        >
          <v-icon>
            mdi-play
          </v-icon>
          Iniciar
        </v-btn>
        <v-btn
            class="ma-2 white--text"
            color="primary"
            :disabled="parar"
            @click="parar_log"
        >
          <v-icon>
            mdi-stop
          </v-icon>
          Parar
        </v-btn>
      </div>
      <v-btn
          color="primary"
          class="ma-2 white--text"
          @click="$emit('atualizar')"
      >
        <v-icon
            dark
            style="margin-right: 10px"
        >
          mdi-refresh
        </v-icon>
        Atualizar
      </v-btn>
    </div>
  </div>
</template>

<script>

  import {mapActions} from "vuex";

  export default {
    name: "Header",
    props: {
      refresh: null
    },
    data() {
      return {
        iniciar: false,
        parar: true,
      }
    },
    methods: {
      ...mapActions(["GET_STATUS", "POST_STATUS"]),
      getStatus() {
        this.GET_STATUS().then( res => {
          if (res.data.status) {
            this.iniciar = true;
            this.parar = false
          }else {
            this.iniciar = false;
            this.parar = true
          }
        })
      },
      iniciar_log() {
        let obj = {
          name: 'exec_orquestrador',
          updated_by: 'admin',
          status: 1
        }
        this.POST_STATUS(obj).then(() => {
          this.iniciar = true;
          this.parar = false;
        })
      },
      parar_log() {
        let obj = {
          name: 'exec_orquestrador',
          updated_by: 'admin',
          status: 0
        }
        this.POST_STATUS(obj).then(() => {
          this.iniciar = false;
          this.parar = true;
        })
      }
    },
    watch: {
      refresh: {
        handler(){
          this.getStatus()
        },
        deep: true
      }
    }
  }
</script>

<style scoped>

.header {
  display: flex;
  flex-direction: row;
  width: 107%;
  justify-content: center;
}

/deep/ .v-toolbar__content {
  width: 100% !important;
}

.cor-texto {
  font-size: 0.85rem;
}

</style>