<template>
  <div class="workflow">

    <div v-if="dialogLoading">
      <v-dialog v-model="dialogLoading" max-width="200px">
        <v-card>
          <v-card-title class="headline text-center">Carregando...</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-icon class="mdi-spin">mdi-loading</v-icon>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="titulo text-center elevation-1">
      <h3>Detalhamento de Workflow</h3>
    </div>

    <!-- botão para adicionar novos workflows, esta teoricamente funcionando como esperado -->

    <div class="mx-2 mb-4" style="width:100%">
      <template>
        <v-dialog transition="dialog-top-transition" max-width="800">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-show='onlyAdminMaster'
                color='gray'
                v-bind="attrs"
                v-on="on"
                id="btn-add-tree"
                block elevation="2"
                large
                @click="limpaItens()"
            >
              ADD WORKFLOW
            </v-btn>
          </template>

          <!-- inicio do dialog de inputs do usuário -->

          <template v-slot:default="dialog">

            <v-card>

              <v-card-title>
                <span class="text-h5">Add Workflow</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <div class="mx-2 mb-4" style="width:100%">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            :disabled="!onlyAdminMaster"
                            label="Description*"
                            v-model="wf_description"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="onlyAdminMaster">
                        <v-text-field
                            label="Root Node"
                            v-model="wf_root_node_id"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            label="Trigger Frequency"
                            v-model="wf_trigger_frequency"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="onlyAdminMaster">
                        <v-text-field
                            label="Workflow Version(integer)"
                            v-model="wf_version"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <span>Status *</span>
                        <v-radio-group v-model="wf_status" row hide-details mandatory>
                          <v-radio value="enabled" label="Enabled" color="blue"></v-radio>
                          <v-radio value="disabled" label="Disabled" color="red"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <p></p>
                      <div v-if="onlyAdminMaster" style="width: 100%;">
                        <v-col cols="12" sm="12" md="12">
                          <span class="text-h6">Metadata</span>
                          <v-btn
                              v-if="!newMetadata"
                              style="margin-left: 10px;"
                              fab
                              x-small
                              @click="addMetadata()"
                          >
                            <v-icon color="green">
                              mdi-plus
                            </v-icon>
                          </v-btn>
                          <v-btn
                              v-else
                              style="margin-left: 10px;"
                              fab
                              color="red"
                              x-small
                              @click="newMetadata = false"
                          >
                            <v-icon color="white">
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-row>
                            <NewMetadata @newMetadata="setNewMetadata" v-if="newMetadata"/>
                          </v-row>
                          <v-row>
                            <MetadataGenerator :key="resetMetadataComponent" v-if="!newMetadata" @removeMetadata="removeMetadata($event)" @editMetadata="editMetadata" v-model="wf_metadata"/>
                          </v-row>
                        </v-col>
                      </div>
                    </v-row>
                  </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog.value = false ; limpaItens()">
                  Cancelar
                </v-btn>

                <!-- impede de salvar se os campos * não estiverem preenchidos -->

                <v-btn
                    @click="createWorkflow(); dialog.value = false; getWorkflow()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </div>

    <div class="data-table">
      <div class="card-table">
        <v-data-table
            :headers="header"
            :items="item.data"
            :page="page"
            :items-per-page="14"
            :sort-by="'id'"
            :sort-desc=true
            hide-default-footer
            hide-default-header
            class="elevation-1"
            @page-count="pageCount = $event"
        >

          <template v-slot:header="{ props: { headers } }">
            <thead>
            <tr>
              <th v-for="(h, index) in headers" :key="h+index" class="test">
                <h4 style="text-align: center">{{ h.text }}</h4>
              </th>
            </tr>
            </thead>
          </template>

          <template v-slot:item.avatar="{ item }">
            <div class="buttons">
              <v-icon
                  size="24"
                  color="primary"
              >
                mdi-format-list-bulleted-square
              </v-icon>
            </div>
          </template>

          <template v-slot:item.initial="{ item }">
            {{ formataData(item.initial) }}
          </template>

          <template v-slot:item.final="{ item }">
            <div v-if="item.final != null">
              {{ formataData(item.final) }}
            </div>
            <div v-else>
              -
            </div>
          </template>

          <tebuttonsmplate v-slot:item.status="{ item }">
            <v-chip
                :color="getColor(item.status)"
                dark
            >
              {{ item.status }}
            </v-chip>
          </tebuttonsmplate>

          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="550px">
              <v-card>
                <v-card-title class="headline">Tem certeza que quer excluir esse workflow?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="confirmDeletWorkflow">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <v-icon
                  v-show="onlyAdminMaster"
                  class="mr-2 icon_acoes"
                  color='blue'
                  title="Editar Árvore"
                  @click="pegaID(item.id, item.workflow_version); $router.push({name: 'Tree'}).catch(() => {})">
                mdi-file-tree
              </v-icon>

              <v-icon
                  class="mr-2 icon_acoes"
                  color='gray'
                  title="Editar Workflow"
                  @click="editWorkflow(item)">
                mdi-pencil
              </v-icon>

              <!--<v-icon
              class="mr-2 icon_acoes"
              color='gray'
              title="Excluir Workflow"
              @click="deletWorkflow(item)">
                mdi-delete
              </v-icon>-->
            </div>
          </template>

        </v-data-table>

        <p></p>

        <div class="text-center pt-2">
          <v-pagination
              class="pagination"
              v-model="page"
              :length="pageCount"
          ></v-pagination>
        </div>
      </div>

    </div>
    <v-alert
        class="alert"
        border="top"
        colored-border
        type="info"
        elevation="2"
        :value="alert"
        color="primary"
    >
      Alteração salva! Enviado para treinamento do robô.
    </v-alert>
  </div>
</template>

<script>

import ModalMaisDetalhes from "../../components/RegistrosEmail/ModalMaisDetalhes";
import GenericDataTable from "../../components/DataTable/GenericDataTable";
import Header from "../../components/Menu/Header";
import axios from '../../plugins/axios'
import moment from "moment";
import MetadataGenerator from "@/components/MetadataGenerator/MetadataGenerator.vue";
import NewMetadata from "@/components/MetadataGenerator/NewMetadata.vue";

export default {
  name: 'Dashboard',
  components: {
    NewMetadata,
    MetadataGenerator,
    Header,
    ModalMaisDetalhes,
    GenericDataTable,
  },

  data() {
    return {
      onlyAdminMaster: localStorage.user_type == 1 ? true : false,
      onlyADMIN: localStorage.user_type == 2 ? true : false,
      newMetadata: false,
      item: '',
      alert: false,
      dialog: false,
      dialogLoading: true,
      dialogDelete: false,
      response_data: '',                  // Armazenamento temporário de
      wf_description: '',                 // todos os inputs exigidos no
      wf_root_node_id: null,              // dialog de criação
      wf_trigger_frequency: "* * * * *",  //
      wf_version: '',                     //
      wf_status: '',                      //
      wf_metadata: '',                    //
      wf_id: '',
      itemDelete: '',
      workflow_data: [],

      header: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "ID",
          sortable: true,
          filterable: true,
          value: "id",
          align: "center",
        },
        {
          text: "Descrição",
          sortable: true,
          filterable: true,
          value: "description",
          align: "left",
        },
        {
          text: "Situação",
          sortable: true,
          filterable: true,
          value: "status",
          align: "center",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          filterable: false,
          value: "actions",
        },
      ],
      headersNodes: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "ID",
          sortable: true,
          filterable: true,
          value: "id",
          align: "center",
        },
        {
          text: "Nome",
          sortable: true,
          filterable: true,
          value: "name",
          align: "left",
        },
        {
          text: "Fila",
          sortable: true,
          filterable: true,
          value: "workr_queue",
          align: "center",
        },
        {
          text: "Status",
          sortable: true,
          filterable: true,
          value: "status",
          align: "center",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          filterable: false,
          value: "actions",
        },
      ],
      itemInfo: {
        code: '',
        nome: '',
        preco: '',
        message: '',
      },
      json: '',
      jsonPrint: [],
      itemData: null,
      workflows: {},
      page: 1,
      pageCount: 0,
      resetMetadataComponent: 0
    }
  },

  mounted() {
    this.getWorkflow();
  },

  watch: {
    dialog() {
      if (this.dialog == true) {
        this.jsonInfo(this.itemData)
      }
    }
  },

  methods: {
    removeMetadata(key){
      delete this.wf_metadata[key]
      this.resetMetadataComponent++
    },
    setNewMetadata(newMetadata) {
      this.wf_metadata = {...this.wf_metadata, ...newMetadata}
      this.newMetadata = false
    },
    editMetadata(val) {
      const index = Object.keys(this.wf_metadata).indexOf(val.key);
      if (index !== -1) {
        this.$set(this.wf_metadata, val.key, val.value);
      }
    },
    addMetadata() {
      this.newMetadata = true
    },
    show(item) {
      this.item = item
      this.$modal.show('email-modal');
    },

    hide(value) {
      this.$modal.hide('email-modal');
      if (value) {
        this.alert = true;
        window.setTimeout(() => {
          this.alert = false;
        }, 2000)
      }
    },

    jsonInfo(item) {
      if (item.data == null) {
        this.jsonPrint = {}
      } else {
        this.jsonPrint = {}
        this.json = item.data.split(',')
        for (var i = 0; i < this.json.length; i++) {
          this.json[i] = this.json[i].split('\"')
          if (this.json[i].length == 7) {
            this.jsonPrint[i] = {
              nome: this.json[i][3],
              data: this.json[i][5],
            }
          } else if (this.json[i].length == 5) {
            this.jsonPrint[i] = {
              nome: this.json[i][1],
              data: this.json[i][3],
            }
          } else if (this.json[i].length == 4) {
            this.jsonPrint[i] = {
              nome: this.json[i][1],
              data: this.json[i][3] + ',' + this.json[i + 1][0],
            }
          } else if (this.json[i].length == 3) {
            this.jsonPrint[i] = {
              nome: this.json[i][1],
              data: this.json[i][2].slice(1).slice(0, -1),
            }
            if (this.jsonPrint[i].nome == "Unnamed: 0") {
              this.jsonPrint[i] = {
                nome: 'Produto',
                data: this.json[i][2].slice(1)
              }
            }
          }
          console.log(this.json[i])
        }
      }
    },

    getColor(info) {
      if (info == 'finished') return 'green'
      else if (info == 'executing') return 'blue'
      else if (info == 'running') return 'blue'
      else if (info == 'iterating') return 'blue'
      else if (info == 'on_queue') return 'orange'
      else if (info == 'canceled') return 'black'
      else if (info == 'error') return 'red'
      else if (info == 'recovered') return 'gray'
      else return 'black'
    },

    formataData(data_evento) {
      return moment(String(data_evento)).format('DD/MM/YYYY HH:mm:ss')
    },

    cancel(id) {
      this.dialogLoading = true
      axios.post('/execution/cancel/' + id, null, {
        headers: {
          "Authorization": localStorage.access_token,
        },
      })
          .then(response => {
            // this.getWorkflows();
          })
          .catch(error => error)
    },

    recover(id) {
      this.dialogLoading = true
      axios.post('/execution/recover/' + id, null, {
        headers: {
          "Authorization": localStorage.access_token,
        },
      })
          .then(response => {
            // this.getWorkflows();
          })
          .catch(error => error)
    },

    createWorkflow() { // cria o workflow e adiciona as informações recebidas pelo dialog
      this.dialogLoading = true
      const description = this.wf_description;
      const root_node_id = this.wf_root_node_id;
      const trigger_frequency = this.wf_trigger_frequency;
      const workflow_version = this.wf_version;
      const status = this.wf_status;
      const metadata = this.wf_metadata;
      const id = this.wf_id;

      if (id == '') {
        axios.post('/workflow', {
          description: description,
          root_node_id: root_node_id,
          trigger_frequency: trigger_frequency,
          workflow_version: workflow_version,
          status: status,
          metadata: metadata,
        }, {
          headers: {
            "Authorization": localStorage.access_token,
          },
        })
            .then((response) => {
              this.response_data = response.data;
              this.limpaItens()
              this.getWorkflow()
            })
            .catch(function (error) {
              console.log(error);
            });
      } else {
        axios.put('/workflow/' + id, {
          description: description,
          root_node_id: root_node_id,
          trigger_frequency: trigger_frequency,
          workflow_version: workflow_version,
          status: status,
          metadata: metadata,
        }, {
          headers: {
            "Authorization": localStorage.access_token,
          },
        })
            .then((response) => {
              this.response_data = response.data;
              this.limpaItens()
              this.getWorkflow()
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },

    getWorkflow() {
      axios.get('/workflow', {
        headers: {
          "Authorization": localStorage.access_token,
        },
      })
          .then(response => {
            let filters = {
              per_page: this.perPage,
              page: this.page,
            }
            this.workflow_data = response.data;
            this.item = this.workflow_data;
            this.dialogLoading = false
          })
          .catch((error) => {
            console.log(error);
          })
    },

    getWorkflowId() {
      axios.get('/workflow', {
        headers: {
          "Authorization": localStorage.access_token,
        },
      })
          .then(response => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          })
    },

    editWorkflow(item) {
      document.getElementById('btn-add-tree').click()
      this.wf_description = item.description
      this.wf_root_node_id = item.root_node_id
      this.wf_version = item.workflow_version
      this.wf_trigger_frequency = item.trigger_frequency
      this.wf_status = item.status
      this.wf_metadata = JSON.parse(item.metadata)
      this.wf_id = item.id
    },

    deletWorkflow(item) {
      this.itemDelete = item.id
      this.dialogDelete = true
    },

    confirmDeletWorkflow() {
      this.dialogLoading = true
      axios
          .delete(
              "/workflow/" +
              this.itemDelete
          )
          .then((response) => {
          })
          .catch((error) => {
            console.log(error);
          });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.itemDelete = "";
    },

    pegaID(id, version) {
      localStorage.treeID = id
      localStorage.WorkflowVersion = version
    },

    limpaItens() {
      this.wf_description = ''
      this.wf_root_node_id = null
      this.wf_trigger_frequency = "* * * * *"
      this.wf_version = ''
      this.wf_status = ''
      this.wf_metadata = ''
      this.wf_id = ''
      this.resetMetadataComponent++
    },

  }

}
</script>


<style scoped>

.workflow {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
}

.data-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
}

.alert {
  display: flex;
  position: fixed;
  width: 72%;
  margin-top: 46%

}

.card-table {
  width: 100%;
  flex: 1 1 100%;
  border-radius: 6px;
}

.titulo {
  background-color: rgb(255, 255, 255);
  justify-content: center;
}

.hidden {
  display: none;
}
</style>
