import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0d4e79',
                secondary: '#00478D',
                light_grey: '#E0E0E0',
            },
        },
    },
});