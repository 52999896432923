import configAxios from 'axios';
import store from "../store";
import router from "../router";

const axios = configAxios.create({
    baseURL: "http://localhost:8080",
    headers: {
    }
});

axios.interceptors.request.use(config => {
    store.commit('beginLoading')
    return config;
});

axios.defaults.validateStatus = (status) => {
    return status >= 200 && status < 300;
};

axios.interceptors.response.use(response => {
    console.log('response:', response);
    return response;
}, error => {
    console.log('error:', error);
    if (error == 'Error: Network Error' || error == 'Error: Request failed with status code 401') {
        router.push({ name: 'Login' })
    }
    return error;
});

export default axios;
