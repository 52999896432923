<template>
   <div class="dashboard">
    <div v-if="dialogLoading">
        <v-dialog v-model="dialogLoading" max-width="200px">
            <v-card>
                <v-card-title class="headline text-center">Carregando...</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-icon class="mdi-spin">mdi-loading</v-icon>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <v-btn
        id="btn-atualizar"
        style="display: none;"
        @click= refresh++
    />

      <div class="titulo text-center elevation-1">
         <h3>Dashboard</h3>
      </div>
        <div v-for="item in workflows" class="card-icon elevation-1">
            <v-dialog
            transition="dialog-top-transition"
            max-width="1500">
                <template v-slot:activator="{ on, attrs }">
                    <div 
                    class="card-icon-body"
                    v-bind="attrs"
                    v-on="on"
                    @click="getInfo(item.id)">
                        <h3 class="text-center">{{item.description}}</h3>

                        <div v-if="item.status != 'disabled'" class="card-icon-i mt-3 active-icon">
                            <v-icon
                                dark
                                class="mdi-48px"
                            >
                            mdi-check-bold
                            </v-icon>
                        </div>
                        <div v-if="item.status == 'disabled'" class="card-icon-i mt-3 disabled-icon">
                            <v-icon
                                dark
                                class="mdi-48px"
                            >
                            mdi-close-thick
                            </v-icon>
                        </div>
                        <div class="text-center mt-3">
                            <p>{{item.status}}</p>
                            <p>{{item.trigger_frequency_translated}}</p>
                        </div>
                    </div>
                </template>

                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                        color="primary"
                        dark
                        >
                            <h3>Workflow {{item.workflow_version}}</h3>
                        </v-toolbar>
                        <v-card-text>
                        <div v-if="loading == false" class="pa-5">
                            <div class="card-icon pt-5">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4" class="align-center">
                                        <div class="card-icon-i mt-3" style="background-color: green;">
                                            <v-icon
                                                dark
                                                class="mdi-48px"
                                            >
                                            mdi-check-bold
                                            </v-icon>
                                        </div>
                                        <h2 class="text-center mb-5 mt-3">Sucessos</h2>
                                        <div class="card-icon mx-12"><h2 class="text-center pa-5" style="width:100%">{{info.executions.finished}}</h2></div>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" class="align-center">
                                        <div class="card-icon-i mt-3" style="background-color: red;">
                                            <v-icon
                                                dark
                                                class="mdi-48px"
                                            >
                                            mdi-close-thick
                                            </v-icon>
                                        </div>
                                        <h2 class="text-center mb-5 mt-3">Erros</h2>
                                        <div class="card-icon mx-12"><h2 class="text-center pa-5" style="width:100%">{{info.executions.failed}}</h2></div>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" class="align-center">
                                        <div class="card-icon-i mt-3" style="background-color: #0d4e79;">
                                            <v-icon
                                                dark
                                                class="mdi-48px"
                                            >
                                            mdi-timer
                                            </v-icon>
                                        </div>
                                        <h2 class="text-center mb-5 mt-3">Tempo</h2>
                                        <div class="card-icon mx-12"><h2 class="text-center pa-5" style="width:100%">{{info.executions.time}}</h2></div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="table">
                                <div class="table-head">
                                    <h3 class="pa-3">Nodes</h3>
                                </div>
                                <div class="">
                                    <div class="table-responsive">
                                        <v-data-table
                                        :headers="headersNodes"
                                        :items="info.nodes"
                                        :sort-by="'data.id'"
                                        :items-per-page="999"
                                        hide-default-footer
                                        class="elevation-1 estilo-tabela">

                                        <template v-slot:no-data>
                                        <h4 class="mb-1 mt-1">Este workflow não possui nodes!</h4>
                                        </template>

                                    </v-data-table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-icon pt-5">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="align-center">
                                        <apexchart type="bar" height="300" :options="chartBar" :series="NodesData" ></apexchart>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="align-center">
                                        <apexchart type="donut" height="300" :options="chartDonut" :series="ErrosData"></apexchart>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <div v-else class="text-center pt-10">
                            <v-icon
                                class="mdi-48px mdi-spin"
                            >
                                mdi-loading
                            </v-icon>
                        </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                        <v-btn
                            text
                            @click="dialog.value = false"
                        >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>

        </div>
       

       <!--
           <div class="card-icon">
            <PieChart :chartData='chartData' :chartOptions='chartOptions'/>
           </div>
           <div class="card-icon">
            <BarChart :chartData='chartData' :chartOptions='chartOptions'/>
           </div>-->
       </div>
   </div>
</template>

<script>
import axios from '../../plugins/axios'
import moment from "moment";
import BarChart from '../../components/Charts/BarChart'
import PieChart from '../../components/Charts/PieChart'
import VueApexCharts from 'vue-apexcharts'

export default {
   name: 'Dashboard',
   components: {
      VueApexCharts,
      BarChart,
      PieChart,
   },
   data(){
       return{
         dialog: false,
         loading: true,
         dialogLoading: true,
         refresh: 0,
         workflows: [],
         info:{},
         headersNodes: [
        {
          text: "ID",
          sortable: true,
          filterable: true,
          value: "data.id",
          align: "center",
        },
        {
          text: "Nome",
          sortable: true,
          filterable: true,
          value: "data.name",
          align: "left",
        },
        {
          text: "Tempo Médio",
          sortable: true,
          filterable: true,
          value: "time",
          align: "center",
        },
        {
          text: "Erros",
          sortable: true,
          filterable: true,
          value: "failed",
          align: "center",
        },
        {
          text: "Sucessos",
          sortable: true,
          filterable: true,
          value: "finished",
          align: "center",
        },
        {
          text: "Iterações",
          sortable: true,
          filterable: true,
          value: "total",
          align: "center",
        },
      ],
      NodesData: [
          {
            name: 'ERROS',
            data: [],
          }, 
          {
            name: 'SUCESSOS',
            data: []
          }
        ],
          chartBar: {
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            colors:['#ff0000', '#00e296'],
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900
                    }
                  }
                }
              },
            },
            xaxis: {
              categories: [],
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1
            }
        },
        ErrosData: [],
        chartDonut: {
            chart: {
            type: 'donut',
            },
            labels: ['ERROS', 'SUCESSOS'],
            colors:['#ff0000', '#00e296'],
            responsive: [{
            breakpoint: 480,
            }]
          },
       }
   },

   mounted() {
        this.getWorkflow();
        this.refresh++;
    },

   watch:{
        refresh(){
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.getWorkflow();
                document.getElementById("btn-atualizar").click();
            }, 5000)
        },
    },
    
   methods: {
    getWorkflow(){
           axios.get('/workflow', {
               headers: {
                   "Authorization": localStorage.access_token,
               },
           })
            .then(response => {
                this.workflows = response.data.data
                this.dialogLoading = false
            })
            .catch(error => error)
      },

       getInfo(id) {
        this.chartBar.xaxis.categories = []
        this.NodesData[0].data = []
        this.NodesData[1].data = []
          axios.get('/workflow/info/' + id, {
              headers: {
                  "Authorization": localStorage.access_token,
              },
          })
            .then(response => {
                var failed = 0
                var finished = 0
                this.info = response.data
                for(var i=0 ; i<this.info.nodes.length ; i++){
                    failed += this.info.nodes[i].failed
                    finished += this.info.nodes[i].finished
                    this.chartBar.xaxis.categories.push(this.info.nodes[i].data.name)
                    this.NodesData[0].data.push(this.info.nodes[i].failed)
                    this.NodesData[1].data.push(this.info.nodes[i].finished)
                }
                console.log(this.chartBar.xaxis.categories)
                this.ErrosData = [failed , finished]
                this.loading = false
            })
            .catch(error => error)
            },
   }
}
</script>

<style>
.dashboard{
   display: flex;
   flex-wrap: wrap;
   margin-left: 20px;
   margin-right: 20px;
}

.card-icon-body{
    width: 100%;
}

.card-chart{
   flex: 1 1 50%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.card-icon{
   flex: 1 1 18%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.card-icon .card-icon-body{
   padding: 20px;
}

.card-icon-i{
   width: 90px;
   height: 90px;
   border-radius: 50%;
   background-color:#e5f4e7;
   margin: 0 auto;
   position: relative;
}

.card-icon .card-icon-body h3{
   text-align: center;
   margin-bottom: 0;
   margin-top: 10px;
   font-size: 18px;
   font-weight: 400;
}

.card-icon-i i{
   top: 50%;
   text-align: center;
   position: absolute;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: 45px;
   color: #5DAE8B;
}

.chart{
   flex: 0 1 60%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.table{
   flex: 0 1 58%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.table-responsive {
   display: block;
   width: 100%;
   overflow-x: auto;
}

.table .table-head{
   border-bottom: 1px solid #eee;
   padding: 0 15px;
}

.table .table-body{
   padding: 25px;
}

.table .table-body table{
   width: 100%;
   border-collapse: collapse;
}

.table table thead tr th{
   padding: 0 10px;
   height: 50px;
   vertical-align: middle;
   color: #666;
   font-weight: 500;
   border-bottom-color: #e2e8f0;
   background-color: #f7f8f9;
   border-top-width: 1px;
   border-bottom-width: 1px;
}

.table tbody tr td, .table tbody tr th {
   padding: 10px;
   border-bottom: 1px solid #eee;
   vertical-align: middle;
   font-size: 14px;
   color: #212529;
   text-align: center;
}

.btn-group{
   display: flex;
   align-items: center;
   justify-content: center;
}

.btn-group a{
   padding: 3px;
   width: 26px;
   height: 26px;
       box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
   margin: 0 5px;
       color: #fff;
   background-color:#5dae8b;
   border-color: #5dae8b;
   border-radius: 0.375rem;
   font-weight: 500;
}

.btn-group a{
       text-align: center;
   line-height: 25px;
   display: block;
}

@media only screen and (max-width: 768px) {
   .table{
       flex: 1 1 100%;
       overflow-x: scroll;
   }
}

.titulo {
   padding: 10px;
   flex: 1 1 100%;
   background: #ffffff;
   border-radius: 6px;
   border: 1px solid #deebfd;
   box-shadow: 0 3px 10px rgba(62,85,120,.045);
   margin: 0 8px 30px;
}

.active-icon{
    background-color: green;
}

.disabled-icon{
    background-color: gray;
}
</style>