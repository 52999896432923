<template>
  <div style="height: 100%; background-color: #E5EAF0">
    <div class="menu-items">
      <MenuBar :menu="botao" @mini="botao=$event" />
      <div class="header">
<!--        <Header @closeMenu="botao = $event" :mini="botao"/>-->
        <div class="content">
          <router-view />
        </div>
      </div>
    </div>
  </div>

</template>
<script>
// import Header from "../../components/Menu/Header";
import MenuBar from "../../components/Menu/MenuBar";

export default {
  name: 'EurofarmaTemplate',
  components: {
    // Header,
    MenuBar
  },
  data() {
    return {
      botao: null,
    }
  },
}
</script>

<style scoped>
  .menu-items {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .header {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .content {
    width: 100%;
    position: relative;
    justify-content: center;
  }
</style>