import axios from '../../plugins/axios'


export const logStore = {
    state: () => ({
        logs: [],
        log: {},
    }),
    mutations: {
        setLog(state, payload) {
            state.log = payload;
        },
        setLogs(state, payload) {
            state.logs = payload;
        },
    },
    actions: {
        GET_LOGS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get('/worker-log', {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setLogs', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_LOG({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/worker-log/' + obj.id)
                    .then(response => {
                        let values = response.data.data
                        commit('setLog', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        LOG_RECLASSIFICATION(context, obj) {
            return new Promise((resolve) => {
                axios.post("/worker-log/store-reclassification", obj)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_EXCEL(context, filters) {
            return new Promise(resolve => {
                axios.get('worker-log/get-excel', {
                    params: filters,
                    responseType: 'arraybuffer'
                })
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => error)
                // window.open('http://localhost/vivest_crm_backend/public/api/worker-log/get-excel', {params: filters});
            })
        }
    },
    getters: {}
}

export default logStore;