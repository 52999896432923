<template>
  <div class="teste">
    <div class="background-image"></div>
    <v-card class="inputs">
      <div class="image-logo">
        <v-img
            width="50px"
            height="150px"
            src="../../assets/logo_smarti.png"
        ></v-img>
      </div>
      <div class="divider"></div>
      <div class="input-item">
        <div>
          <h3 class="mb-10">Esqueceu sua senha?</h3>
          <FormFields
              :fields="fields"
              :validate="validateFields"
              @update:values="inputsValue = $event"
              @checkValidity="resetPassword($event)"
          />
        </div>
        <div class="buttons">
          <v-btn
              @click="$router.back()"
              outlined
              color="primary"
              width="40%"

          >Voltar
          </v-btn>
          <v-btn
              outlined
              width="40%"
              @click.native="validate()"
              color="primary"
          >Enviar
          </v-btn>
        </div>
      </div>
    </v-card>

  </div>
</template>

<script>
import FormFields from "../../components/FormFields";

export default {
  name: 'RedefenirSenha',
  components: {
    FormFields
  },
  data() {
    return {
      validateFields: 0,
      fields: {},
      inputsValue: {
        email: null,
      }
    }
  },
  mounted() {
    this.initFields();
  },
  methods: {
    initFields() {
      this.fields = {
        email: {
          identificacao_interna: 'email',
          field: 'TextField',
          cols: 12,
          label: 'Digite o email:',
          dinamicRules: [(v) => !!v || "Preencha o email"],
          disabled: false,
          valueField: this.inputsValue.email,
        },
      }
    },
    validate() {
      this.validateFields++;
    },
    resetPassword(event) {
      if(event.isValid){
        console.log(event)
      }
    }
  }

}
</script>

<style scoped>
h1 {
  color: #002E5D;
  margin-top: 70%;
}

.background-image {
  width: 100%;
  height: 100%;
  /*background-image: url(../../../assets/wallpaper.jpg);*/
  background-color: #0d4e79;
  /*filter: blur(8px);*/
  /*-webkit-filter: blur(8px);*/
  /*background-attachment: fixed;*/
  /*background-position: 100%;*/
  background-repeat: no-repeat;
  background-size: cover;

}

.inputs {
  background-color: #fff;
  /*border: 0.05em solid #002E5D;*/
  border-radius: 0.5em;
  color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  z-index: 2;
  text-align: center;
  width: 24em;
  height: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.input-item {
  padding: 1em 0 0 0;
  margin-top: 1em;
  width: 70%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.image-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.divider {
  width: 100%;
  border-bottom: 0.4em solid #0d4e79;

}

.teste {
  width: 100%;
  height: 100%;
  display: block;
}

.buttons {
  display: flex;
  flex-display: row;
  justify-content: space-between;
  padding: 0px 0px 50px 0px;
}
</style>