<script>

export default {
  methods: {
    arrayToMetaObject(array) {
      return array.map(arr => {
        return {
          type: Array.isArray(arr) ? 'array' : typeof arr,
          value: arr
        }
      })
    },
    objectToArrayMetaObject(object){
      let keys = Object.keys(object)
      return keys.map(key => {
        return {
          key: key,
          type: Array.isArray(object[key]) ? 'array' : typeof object[key],
          value: object[key]
        }
      })
    }
  },
};
</script>

